import React, { useState, useEffect, useCallback, useRef } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Main, ContentHeader, Content } from '@r1/wireframe-primary'
import { Link, Flex, Box, Text } from '@r1/ui-kit'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'

import { Warehouse, PickupDescription } from '@r1-webui/facilitypickupscheduler-v1'

import { useURLSearchParamState } from '../../../../utils/hooks/useURLSearchParamState'

import { Loader } from './components/Loader'
import { DetailsPickupSingleModal } from './components/modals/DetailsPickupSingleModal'
import { DetailsPickupBundleModal } from './components/modals/DetailsPickupBundleModal'
import { DetailsPickupModalProps } from './components/modals/DetailsPickupModalProps'
import { DetailsPickupLocalB2CModal } from './components/modals/DetailsPickupLocalB2CModal'
import {
  DetailsReservationModal,
  DetailsReservationModalProps,
} from './components/modals/DetailsReservationModal'

import './style.css'
import { CreateReservationModal } from './components/modals/CreateReservationModal'
import { CreateSinglePickupModal } from './components/modals/CreateSinglePickupModal'

import { WarehouseSelect } from './components/WarehouseSelect'

import { Reservation } from './types'
import { useBusinessHours } from './hooks/useBusinessHours'
import { useCalendarEvents } from './hooks/useCalendarEvents'
import { useWarehouses } from './hooks/useWarehouses'
import { CreateOrderBundleModal } from './components/modals/CreateBundlePickupModal'
import { EventDot } from './components/EventDot'
import { COLORS } from './constansts'
import { CreateLocalB2CPickupModal } from './components/modals/CreateLocalB2CPickupModal'

type ReservationModal = {
  type: 'reservation'
  payload: Reservation
}

type PickupModal = {
  type: 'pickup'
  payload: PickupDescription
}

type ModalProps = ReservationModal | PickupModal

export const SchedulePickup = () => {
  const calendarComponentRef = useRef<FullCalendar | null>(null)
  const [modalProps, setModalProps] = useState<ModalProps | null>(null)
  const [warehouse, setWarehouse] = useState<Warehouse | null>(null)
  const [orderIds, setOrderIds] = useState<string[]>([])
  const { isWarehousesloading: warehouseLoading, warehouses } = useWarehouses()
  const {
    businessHoursForCalendar: warehouseBusinessHoursForCalendar,
    businessHoursByDays: warehouseBusinessHoursForModal,
  } = useBusinessHours({
    warehouse,
  })
  const { get: getWarehouseIdFromUrl } = useURLSearchParamState('warehouseLocationId')
  const { get: getOrderIdsFromUrl } = useURLSearchParamState('orderIds')

  useEffect(() => {
    const warehouseLocationIdFromQuery = getWarehouseIdFromUrl()
    const foundWarehouse = warehouses.find(x => x.locationId === warehouseLocationIdFromQuery)
    if (foundWarehouse) {
      setWarehouse(foundWarehouse)
    }
    const ordersString = getOrderIdsFromUrl()
    if (ordersString) {
      const orderIdsFromQuery = ordersString.split(',')
      setOrderIds(orderIdsFromQuery)
    }
  }, [getOrderIdsFromUrl, getWarehouseIdFromUrl, warehouses])

  const onReservationClick = useCallback(
    (reservation: Reservation) => {
      setModalProps({
        type: 'reservation',
        payload: reservation,
      })
    },
    [setModalProps],
  )

  const onPickupClick = useCallback(
    (pickup: PickupDescription) => {
      setModalProps({
        type: 'pickup',
        payload: pickup,
      })
    },
    [setModalProps],
  )

  const {
    isEventsLoading: loading,
    events,
    ...handlers
  } = useCalendarEvents({
    orderIds,
    warehouse,
    calendarComponentRef,
    onReservationClick,
    onPickupClick,
  })

  const onModalClose = () => {
    setModalProps(null)
  }

  const handleRemoveWarehouse = useCallback(() => {
    setWarehouse(null)
    setOrderIds([]) // ordersIds are initialized only one time (first open page) if warehouse has been changed - clear orderIds filter
    handlers.onClearCalendarEvents()
  }, [handlers])

  const handleChangeWarehouse = useCallback((newWarehouse: Warehouse) => {
    setWarehouse(newWarehouse)
    setOrderIds([]) // ordersIds are initialized only one time (first open page) if warehouse has been changed - clear orderIds filter
  }, [])

  const getPickUpModalProps = (pickup: PickupDescription): DetailsPickupModalProps => {
    const props: DetailsPickupModalProps = {
      pickupId: pickup.id,
      warehouseLocationId: warehouse?.locationId || '',
      pickupType: pickup.pickupType,
      onClose: onModalClose,
      onDelete: handlers.onDeletePickupEvent,
    }
    return props
  }

  const getReservationModalProps = (reservation: Reservation): DetailsReservationModalProps => {
    const props: DetailsReservationModalProps = {
      notes: reservation.notes,
      createdBy: reservation.createdBy,
      reservationId: reservation.reservationId,
      period: reservation.period,
      pickupDate: reservation.pickupDate,
      onClose: onModalClose,
      onDelete: handlers.onDeleteReservationEvent,
      onBind: handlers.onBindReservationWithOrder,
    }
    return props
  }

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowPickupScheduleView]}>
      <Main fullScreen>
        <ContentHeader>
          <ContentHeader.Title>Pickup Schedule</ContentHeader.Title>
        </ContentHeader>
        <Content>
          <Flex column pt="XXL" spaceBetween="XXL">
            <Flex column>
              <Box>
                <Link
                  href={`webcal://${window.location.hostname}/utilities/api/warehouses/calendarfeed?warehouseLocationId=${warehouse?.locationId}`}
                >
                  Subscribe using the Default Desktop Application (Outlook, Mac OS X Calendar)
                </Link>
              </Box>
              <Box>or</Box>
              <Box>
                <Link
                  href={`/utilities/api/warehouses/calendarfeed?warehouseLocationId=${warehouse?.locationId}`}
                >
                  Download calendar file (.ics)
                </Link>
              </Box>
            </Flex>
            <Flex spaceBetween="XXL">
              <Flex column spaceBetween="XXXL" basis={0.12}>
                <Flex column spaceBetween="S">
                  <Box>
                    <Text>Legend:</Text>
                  </Box>
                  <Flex ml="L" align="center">
                    <EventDot color={COLORS.RESERVE} /> <Text> - Reserve</Text>
                  </Flex>
                  <Flex ml="L" align="center">
                    <EventDot color={COLORS.SINGLE_PICKUP} /> <Text> - Single Pickup</Text>
                  </Flex>
                  <Flex ml="L" align="center">
                    <EventDot color={COLORS.BUNDLE_PICKUP} /> <Text> - Bundle Pickup</Text>
                  </Flex>
                  <Flex ml="L" align="center">
                    <EventDot color={COLORS.LOCAL_B2C_PICKUP} /> <Text> - B2C Local Pickup</Text>
                  </Flex>
                </Flex>
                <WarehouseSelect
                  selectedWarehouse={warehouse}
                  warehouses={warehouses}
                  loading={warehouseLoading}
                  onChange={handleChangeWarehouse}
                  onRemove={handleRemoveWarehouse}
                />
                {!!warehouse && (
                  <CreateReservationModal
                    warehouseLocationId={warehouse.locationId}
                    warehouseWorkingHours={warehouseBusinessHoursForModal}
                    warehouseExceptionDates={warehouse.schedule.exceptions}
                    onAdd={handlers.onAddReservationEvent}
                  />
                )}
                {!!warehouse && (
                  <CreateSinglePickupModal
                    warehouseLocationId={warehouse.locationId}
                    warehouseWorkingHours={warehouseBusinessHoursForModal}
                    warehouseExceptionDates={warehouse.schedule.exceptions}
                    onAdd={handlers.onAddPickupEvent}
                  />
                )}
                {!!warehouse && (
                  <CreateOrderBundleModal
                    warehouseLocationId={warehouse.locationId}
                    warehouseWorkingHours={warehouseBusinessHoursForModal}
                    warehouseExceptionDates={warehouse.schedule.exceptions}
                    refreshCalendarEvents={handlers.refreshCalendarEvents}
                  />
                )}
                {!!warehouse && (
                  <CreateLocalB2CPickupModal
                    warehouseLocationId={warehouse.locationId}
                    warehouseWorkingHours={warehouseBusinessHoursForModal}
                    warehouseExceptionDates={warehouse.schedule.exceptions}
                    onAdd={handlers.onAddPickupEvent}
                  />
                )}
              </Flex>
              <div style={{ display: 'flex', width: '70%', flexDirection: 'column' }}>
                {loading ? (
                  <Loader />
                ) : (
                  <FullCalendar
                    ref={calendarComponentRef}
                    weekends
                    editable
                    plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]}
                    timeZone="UTC"
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth,listWeek,timeGridWeek,timeGridDay',
                    }}
                    initialView="dayGridMonth"
                    // prevent event to be dropped outside of business hours
                    eventConstraint="businessHours"
                    events={events}
                    eventDrop={handlers.onCalendarEventDragDrop}
                    eventResize={handlers.onCalendarEventResize}
                    businessHours={warehouseBusinessHoursForCalendar}
                    eventClick={handlers.onCalendarEventClick}
                  />
                )}
              </div>
            </Flex>
          </Flex>
        </Content>
        {!!modalProps && modalProps.type === 'reservation' && (
          <DetailsReservationModal {...getReservationModalProps(modalProps.payload)} />
        )}
        {!!modalProps &&
          modalProps.type === 'pickup' &&
          modalProps.payload.pickupType === 'Single' && (
            <DetailsPickupSingleModal {...getPickUpModalProps(modalProps.payload)} />
          )}
        {!!modalProps &&
          modalProps.type === 'pickup' &&
          modalProps.payload.pickupType === 'Bundle' && (
            <DetailsPickupBundleModal {...getPickUpModalProps(modalProps.payload)} />
          )}
        {!!modalProps &&
          modalProps.type === 'pickup' &&
          modalProps.payload.pickupType === 'LocalB2C' && (
            <DetailsPickupLocalB2CModal {...getPickUpModalProps(modalProps.payload)} />
          )}
      </Main>
    </PageAccessControl>
  )
}
