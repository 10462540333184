import React from 'react'
import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { Button, Dropdown, Flex, Icon, Text } from '@r1/ui-kit'
import { FINISHED_OFFER_STATUSES } from '../const'
import {
  getOfferCounterActionAbility,
  getOffersApprovalActionAbility,
  getOffersRejectActionAbility,
} from '../utils'
import { OfferAction } from '../types'

export function OfferActionsMenu({
  offer,
  allowedToEdit,
  onActionClick,
}: {
  offer: Offer
  allowedToEdit: boolean
  onActionClick: (offer: Offer, action: OfferAction) => void
}) {
  const isOfferFinished = FINISHED_OFFER_STATUSES.includes(offer.status)

  if (isOfferFinished || !allowedToEdit) {
    return (
      <Flex basis={1} align="center" justify="flex-start">
        <Icon
          type="history"
          cursor="pointer"
          onClick={() => onActionClick(offer, OfferAction.History)}
        />
      </Flex>
    )
  }

  const approvalAbility = getOffersApprovalActionAbility([offer])

  const counterOfferAbility = getOfferCounterActionAbility([offer])

  const rejectAbility = getOffersRejectActionAbility([offer])

  return (
    <Flex basis={1} align="center" justify="space-between">
      <Icon
        type="history"
        cursor="pointer"
        onClick={() => onActionClick(offer, OfferAction.History)}
      />

      <Dropdown.Menu
        items={[
          {
            id: OfferAction.Approve,
            disabled: !approvalAbility.canPerform,
            title: (
              <Flex column>
                <Text>Approve</Text>
                {!approvalAbility.canPerform ? (
                  <Text type="caption" maxLines={2}>
                    {approvalAbility.reasonMessage}
                  </Text>
                ) : null}
              </Flex>
            ),
          },
          {
            id: OfferAction.Counter,
            disabled: !counterOfferAbility.canPerform,
            title: (
              <Flex column>
                <Text>Counter Offer</Text>
                {!counterOfferAbility.canPerform ? (
                  <Text type="caption" maxLines={2}>
                    {counterOfferAbility.reasonMessage}
                  </Text>
                ) : null}
              </Flex>
            ),
          },
          {
            id: OfferAction.Reject,
            disabled: !rejectAbility.canPerform,
            title: (
              <Flex column>
                <Text>Reject</Text>
                {!rejectAbility.canPerform ? (
                  <Text type="caption" maxLines={2}>
                    {rejectAbility.reasonMessage}
                  </Text>
                ) : null}
              </Flex>
            ),
          },
        ]}
        mode="click"
        placement="bottom-end"
        onSelectItem={itemId => {
          onActionClick(offer, itemId)
        }}
      >
        <Flex justify="center">
          <Button transparent shape="square" size="S">
            <Icon type="dots" />
          </Button>
        </Flex>
      </Dropdown.Menu>
    </Flex>
  )
}
