import * as React from 'react'
import * as R from 'ramda'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAccessControl } from '@r1/core-blocks'
import { Drawer, Placeholder, BottomButtonsBar } from '@r1/ui-kit'
import { Address, Facility } from '@r1-webui/facilities-v1'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { FacilityForm } from '../FacilityForm'
import { useFacility } from '../../hooks/useFacility'
import { updateFacilityLocation } from '../../api/updateFacilityLocation'
import { createFacilityLocation } from '../../api/createFacilityLocation'
import { schema } from '../../schemas/FacilityForm.schema'
import { validateShifts } from '../../utils/validateShifts'
import { getEmptyFacility } from '../../utils/getEmptyFacility'

export const FacilityDrawer = ({
  isOpen,
  facilityId,
  onClose,
}: {
  isOpen: boolean
  facilityId?: string | null
  onClose: () => void
}) => {
  const [{ allowFacilityEdit }] = useAccessControl()
  const [loading, setLoading] = React.useState(false)
  const { facility } = useFacility({ facilityId, onError: onClose })

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
  })

  React.useEffect(() => {
    if (facility) {
      const groupedAddresses = R.groupBy(({ addressType }) => addressType, facility.addresses)
      const addresses: { [key: string]: Address } = {}
      Object.keys(groupedAddresses).forEach(addressType => {
        const [address] = groupedAddresses[addressType]
        addresses[addressType] = address
      })
      reset({ ...facility, addresses })
    } else {
      reset(getEmptyFacility())
    }
  }, [facility, reset])

  const isEditFacility = Boolean(facilityId)
  const title = isEditFacility ? facility?.name : 'Create Facility'
  const isLoadingFacility = Boolean(facilityId && !facility)

  const putFacilityChanges = (facilityData: Facility): Promise<boolean> => {
    if (facilityId) {
      return updateFacilityLocation(facilityId, facilityData)
    }
    return createFacilityLocation(facilityData)
  }

  const onSubmit = () =>
    handleSubmit(async data => {
      clearErrors()
      const { shifts = [] } = data
      const shiftsErrors = validateShifts(shifts)

      if (Object.keys(shiftsErrors).length === 0) {
        setLoading(true)
        const isSucceeded = await putFacilityChanges(data as Facility)
        setLoading(false)

        if (isSucceeded) {
          onClose()
        }
      } else {
        Object.keys(shiftsErrors).forEach(key => {
          setError(key, shiftsErrors[key])
        })
      }
    })()

  const bottomActionButtons: ControlledActionButtons = [
    {
      title: 'Cancel',
      align: 'right',
      color: 'secondary',
      onClick: onClose,
    },
    {
      title: isEditFacility ? 'Save' : 'Create',
      align: 'right',
      loading,
      disabled: !allowFacilityEdit || loading,
      onClick: onSubmit,
    },
  ]

  return (
    <Drawer.Form
      closeButton
      placement="right"
      size={1200}
      show={isOpen}
      title={title}
      loading={isLoadingFacility}
      footer={<BottomButtonsBar bottomActionButtons={bottomActionButtons} />}
      label="Facility Location Settings"
      onClose={onClose}
    >
      {isLoadingFacility ? (
        <Placeholder type="form" height={10} />
      ) : (
        <FacilityForm facility={facility} control={control} errors={errors} />
      )}
    </Drawer.Form>
  )
}
