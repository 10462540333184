import { cookie } from '@r1/http-client'
import { useMemo } from 'react'

export const enum CompanyModelTypeEnum {
  Host = 'Host',
  Retailer = 'Retailer',
  Vendor = 'Vendor',
}

type CompanyInfo = {
  id: string
  name: string
  type: CompanyModelTypeEnum
}

interface CookiesUserInfo {
  company: CompanyInfo
}
export const useUserInfo = (): CookiesUserInfo => {
  const company = JSON.parse(cookie.getItem('company') || '') as CompanyInfo
  return useMemo(
    () => ({
      company,
    }),
    [company],
  )
}
