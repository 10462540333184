/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Offer, OfferPreview } from '@r1-webui/gowholesale-offermanagement-v1'
import {
  Modal,
  Flex,
  NumericInput,
  Text,
  Box,
  HR,
  Button,
  FormField,
  StructuredList,
  Loader,
} from '@r1/ui-kit'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal'
import { offerManagementApi } from '../../../../api'
import { OfferToCounter } from '../../../../types'
import {
  getCounterAllConfirmMessage,
  getCounterConfirmMessage,
  getOtherOffersColumnsSettings,
  validatePrice,
  validateQuantity,
} from './utils'

const Title = styled.div`
  font-size: 1.25rem;
  line-height: 28px;
`

const NowrapTextWrapper = styled.span`
  [class*='StyledText'] {
    text-wrap: nowrap;
  }
`

type CounterOfferModalProps = {
  currentOffer: Offer
  show: boolean
  onCounter: (payload: { counterPrice: number; counterQuantity: number }) => void
  onCounterAll: (payload: {
    offersToCounter: OfferToCounter[]
    counterPrice: number
    counterQuantity: number
  }) => void
  onClose: () => void
}

const otherOffersColumnsSettings = getOtherOffersColumnsSettings()

type ConfirmData =
  | {
      type: 'counter'
      message: JSX.Element
    }
  | {
      type: 'counterAll'
      message: JSX.Element
    }

export const CounterOfferModal = ({
  currentOffer,
  show,
  onCounter,
  onCounterAll,
  onClose,
}: CounterOfferModalProps) => {
  const [price, setPrice] = useState<number>(Number(currentOffer.askPrice?.amount || 0))
  const [priceError, setPriceError] = useState<string | null>(null)
  const [quantity, setQuantity] = useState<number>(currentOffer.quantity.value)
  const [quantityError, setQuantityError] = useState<string | null>(null)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [otherOffers, setOtherOffers] = useState<OfferPreview[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [confirmData, setConfirmData] = useState<ConfirmData | null>(null)

  const onPriceChanged = (value: string | null) => {
    const numberValue = Number(value)
    const newPrice = typeof value === 'string' && numberValue >= 0 ? numberValue : 0
    setPrice(newPrice)
  }

  const onQuantityChanged = (value: string | null) => {
    const numberValue = Number(value)
    const newQuantity = typeof value === 'string' && numberValue >= 0 ? numberValue : 0
    setQuantity(newQuantity)
  }

  const fetchOtherOffers = async (offerId: string) => {
    setIsLoading(true)
    setOtherOffers(await offerManagementApi.getSimilarOffers(offerId))
    setIsLoading(false)
  }

  useEffect(() => {
    fetchOtherOffers(currentOffer.id)
  }, [currentOffer])

  useEffect(() => {
    const errorMessage = validatePrice(price)
    setPriceError(errorMessage)
  }, [price])

  // Validate quantity change
  useEffect(() => {
    const errorMessage = validateQuantity(quantity, currentOffer)
    setQuantityError(errorMessage)
  }, [quantity, currentOffer])

  const isCounterDisabled = Boolean(priceError) || Boolean(quantityError)

  const onCounterClick = useCallback(() => {
    const message = getCounterConfirmMessage({ quantity, price, currentOffer })
    setConfirmData({ type: 'counter', message })
    setIsConfirmModalOpen(true)
  }, [currentOffer, price, quantity])

  const onCounterAllClick = () => {
    const message = getCounterAllConfirmMessage({ quantity, price, currentOffer })
    setConfirmData({ type: 'counterAll', message })
    setIsConfirmModalOpen(true)
  }

  const modalActionButtons: ControlledActionButtons = useMemo(() => {
    if (otherOffers.length || isLoading) return []
    return [
      {
        title: 'Confirm',
        color: 'primary',
        onClick: onCounterClick,
        disabled: isCounterDisabled,
        align: 'right',
      },
    ]
  }, [otherOffers.length, isLoading, isCounterDisabled, onCounterClick])

  const onConfirmModalConfirm = () => {
    if (confirmData?.type === 'counter') {
      onCounter({
        counterPrice: price,
        counterQuantity: quantity,
      })
    }
    if (confirmData?.type === 'counterAll') {
      const offersToCounter = [
        { id: currentOffer.id, version: currentOffer.version },
        ...otherOffers.map(o => ({ id: o.id, version: o.version })),
      ]
      onCounterAll({
        offersToCounter,
        counterPrice: price,
        counterQuantity: quantity,
      })
    }
  }

  const onConfirmModalClose = () => {
    setConfirmData(null)
    setIsConfirmModalOpen(false)
  }

  const renderModalBody = () => {
    if (isLoading) return <Loader />

    return (
      <Flex column spaceBetween="S">
        <Flex column spaceBetween="XXS">
          <Text weight="bold" overflow="rtl-ellipsis">
            Product
          </Text>
          <Text>{currentOffer.product.name}</Text>
        </Flex>

        <Flex>
          <Box minWidth={160}>
            <Text weight="bold" overflow="rtl-ellipsis">
              Quantity
            </Text>
          </Box>
          <Text>{currentOffer.quantity.value}</Text>
        </Flex>

        <Flex>
          <Box minWidth={160}>
            <Text weight="bold" overflow="rtl-ellipsis">
              Current offer
            </Text>
          </Box>
          <Text>
            &#36;{currentOffer.price.amount}
            &nbsp;per&nbsp;item
          </Text>
        </Flex>

        <Flex>
          <Box minWidth={160}>
            <Text weight="bold" overflow="rtl-ellipsis">
              Ask
            </Text>
          </Box>
          <Text>&#36;{currentOffer.product.price.amount}</Text>
        </Flex>

        {currentOffer.askPrice ? (
          <Flex>
            <Box minWidth={160}>
              <Text weight="bold" overflow="rtl-ellipsis">
                Current Counter Offer
              </Text>
            </Box>
            <Text>&#36;{currentOffer.askPrice.amount}</Text>
          </Flex>
        ) : null}

        <Box pt="XS">
          <HR />
        </Box>

        <Title>Counter Offer</Title>

        <Flex spaceBetween="M">
          <FormField error={Boolean(priceError)}>
            <FormField.Label>Price</FormField.Label>
            <Flex align="center" spaceBetween="XXS">
              <NumericInput maxFractionDigits={0} value={price} onChange={onPriceChanged} />
              <NowrapTextWrapper>
                <Text>&#36;&nbsp;per&nbsp;item</Text>
              </NowrapTextWrapper>
            </Flex>
            <FormField.Error>{priceError}</FormField.Error>
          </FormField>

          <FormField error={Boolean(quantityError)}>
            <FormField.Label>Quantity</FormField.Label>
            <NumericInput maxFractionDigits={0} value={quantity} onChange={onQuantityChanged} />
            <FormField.Error>{quantityError}</FormField.Error>
          </FormField>
        </Flex>

        {otherOffers.length ? (
          <>
            <Box alignSelf="flex-end">
              <Flex spaceBetween="M">
                <Button
                  color={otherOffers.length ? 'secondary' : 'primary'}
                  disabled={isCounterDisabled}
                  onClick={onCounterClick}
                >
                  Confirm
                </Button>
                <Button disabled={isCounterDisabled} onClick={onCounterAllClick}>
                  Counter All
                </Button>
              </Flex>
            </Box>

            <Title>Other Offers</Title>

            <StructuredList<OfferPreview>
              data={otherOffers}
              columnsSettings={otherOffersColumnsSettings}
            />
          </>
        ) : null}
      </Flex>
    )
  }

  return (
    <Modal
      isControlled
      show={show}
      size="S"
      title={
        <Flex justify="space-between" align="center">
          <Title>Make a Counter Offer</Title>
          <Button transparent color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      }
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onClose}
    >
      {renderModalBody()}
      <DialogModal
        message={confirmData?.message ?? null}
        show={isConfirmModalOpen}
        onConfirm={onConfirmModalConfirm}
        onClose={onConfirmModalClose}
      />
    </Modal>
  )
}
