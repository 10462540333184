import { createTrackingStatusApi, baseUri } from '@r1-webui/shipping-trackingstatus-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = '/uisvc/shipment-management'

export const trackingStatusApi = createTrackingStatusApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `${API_PREFIX}/${baseUri}`,
})
