// @flow

import React, { memo, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Flex, Icon, Text } from '@r1/ui-kit'
import { CustomIcon, getDataTestId, IssueFeedbackModal } from '@r1/wireframe-primary'

import { useToggle } from '../../utils'
import { redirect } from './utils'

type Props = {|
  isRMP?: boolean,
  pagePrefixUrl: string,
  httpClient: $AnyObject,
  onCookieBannerOpen: () => void,
|}

const SEND_ISSUE_FEEDBACK = 'SEND_ISSUE_FEEDBACK'
const MY_ISSUES = 'MY_ISSUES'
const SITE_MAP = 'SITE_MAP'
const PRIVACY_SETTINGS = 'PRIVACY SETTINGS'

const DEFAULT_DROPDOWN_ITEMS = [
  {
    id: SEND_ISSUE_FEEDBACK,
    title: <Text>Report issue / Feedback</Text>,
    disabled: false,
  },
  {
    id: MY_ISSUES,
    title: (
      <Flex minWidth={1} justify="space-between">
        <Text>My Issues</Text> <Icon type="openInNewTab" />
      </Flex>
    ),
    disabled: false,
  },
  {
    id: SITE_MAP,
    title: <Text>Site Map</Text>,
    disabled: false,
  },
  {
    id: PRIVACY_SETTINGS,
    title: <Text>Privacy Settings</Text>,
    disabled: false,
  },
]

export const HelpMenu = memo<Props>(({ httpClient, pagePrefixUrl, onCookieBannerOpen, isRMP }) => {
  const { state: isModalOpen, toggle: toggleModal } = useToggle(false)
  const navigate = useNavigate()

  const handleOpenMyIssues = useCallback(() => {
    window.open('https://support.r1ss.com/servicedesk/customer/user/requests')
  }, [])

  const handleOpenSiteMap = useCallback(() => {
    redirect(`${pagePrefixUrl}/sitemap`, navigate)
  }, [navigate, pagePrefixUrl])

  const onSelectItem = useCallback(
    (id: string) => {
      switch (id) {
        case SEND_ISSUE_FEEDBACK: {
          toggleModal()
          break
        }
        case MY_ISSUES: {
          handleOpenMyIssues()
          break
        }
        case SITE_MAP: {
          handleOpenSiteMap()
          break
        }
        case PRIVACY_SETTINGS: {
          onCookieBannerOpen()
          break
        }
        default:
      }
    },
    [toggleModal, handleOpenMyIssues, handleOpenSiteMap, onCookieBannerOpen],
  )

  const dropdownItems = useMemo(
    () => (isRMP ? [DEFAULT_DROPDOWN_ITEMS[0]] : DEFAULT_DROPDOWN_ITEMS),
    [],
  )

  return (
    <div>
      <Dropdown.Menu
        mode="click"
        placement="bottom-end"
        size="S"
        zIndex={51}
        items={dropdownItems}
        data-test-id={getDataTestId('header', 'help-drop-down-menu')}
        onSelectItem={onSelectItem}
      >
        <CustomIcon type="dots/horizontal" dataTestId={getDataTestId('header', 'help-menu-btn')} />
      </Dropdown.Menu>

      <IssueFeedbackModal httpClient={httpClient} show={isModalOpen} onClose={toggleModal} />
    </div>
  )
})
