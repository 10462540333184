import { isRMP } from '../../routes/MasterLayout/utils'
import { iconsMock } from '../mocks/icons'

export interface ResponseError {
  message?: string
  response?: {
    url?: string
    config?: {
      url?: string
    }
  }
}

export const mockR1UserManagement = {
  response: (response: Array<{ originalHeight?: string }>) => {
    // TODO: research why config transforms after access token is expired
    if (!isRMP) {
      return response
    }
    const isIconsResponse = Array.isArray(response) && response?.[0]?.originalHeight
    if (isIconsResponse) {
      return [...response, ...iconsMock]
    }

    return response
  },
  error: (error: ResponseError) => {
    // TODO: research why config transforms after access token is expired
    const url = error.response?.config?.url ?? error.response?.url
    if (isRMP && url?.startsWith('/uisvc/svg-storage/api/')) {
      return iconsMock
    }

    if (isRMP && url?.startsWith('/uisvc/user-management/')) {
      console.log('MOCKED USER MANAGEMENT')
      return {}
    }
    return Promise.reject(error)
  },
}
