import type { State } from './UserCreationFormController'

export const defaultState: State = {
  navigation: {
    currentStep: 'generalInfo',
    steps: [
      {
        id: 'generalInfo',
        complete: false,
        error: false,
      },
      {
        id: 'programs',
        complete: false,
        error: false,
      },
      {
        id: 'roles',
        complete: false,
        error: false,
      },
      {
        id: 'summary',
        complete: false,
        error: false,
      },
    ],
  },
  // WARNING! Keep all user state with default empty string values
  // reason: the validation is not ideal and works only with values are String. TODO
  user: {
    active: true,
    changePasswordOnNextLogin: false,
    userType: 'FullTimeEmployee',
    login: '',
    password: '',
    fullName: '',
    timeZoneId: '',
    userCompanyId: '',
    locationId: '',
    roles: [],
    isSalesRepresentative: false,
    reportLogins: [],
    reportLoginId: '',
    programsSettings: {
      selectedProgramIds: [],
      selectedProgramPresetIds: [],
    },
  },
  errors: {},
  isVisibleImageEditor: false,
}
