import * as React from 'react'
import { Route, Navigate, Routes, useLocation } from 'react-router-dom'

import { ROUTES } from '../../navigation'

import ProfileConfigurator from '../../containers/tax-commitment-reporter/routes/ProfileConfigurator'
import { PurchaseOrders } from '../../containers/tax-commitment-reporter/routes/PurchaseOrders'
import { FinancialEvents } from '../../containers/tax-commitment-reporter/routes/FinancialEvents'

import { PurposeList } from '../../containers/espm/routes/PurposeList'
import { ProfileList } from '../../containers/espm/routes/ProfileList'
import { EditProfile } from '../../containers/espm/routes/EditProfile'
import { CreateProfile } from '../../containers/espm/routes/CreateProfile'

import { ViewUsersGrid } from '../../containers/user-management/routes/ViewUsersGrid/ViewUsersGrid.tsx'
import { CreateUser } from '../../containers/user-management/routes/CreateUser/CreateUser.tsx'
import { EditUser } from '../../containers/user-management/routes/EditUser/EditUser.tsx'
import { RolesGrid } from '../../containers/user-management/routes/Roles/RolesGrid/RolesGrid.tsx'
import { RolePage } from '../../containers/user-management/routes/Roles/RolePage/RolePage.tsx'

import { ViewCategoriesGrid } from '../../containers/product-catalog/routes/ViewCategoriesGrid'
import { CategoryCreate } from '../../containers/product-catalog/routes/CategoryCreate'
import { CategoryDetails } from '../../containers/product-catalog/routes/CategoryDetails'
import { ViewProductsGrid } from '../../containers/product-catalog/routes/ViewProductsGrid'
import { ProductDetails } from '../../containers/product-catalog/routes/ProductDetails'
import { ProductView } from '../../containers/product-catalog/routes/ProductDetails/ProductView'
import { PricingRules } from '../../containers/product-catalog/routes/PricingRules'
import {
  GlobalPricingRules,
  GlobalPricingRuleView,
} from '../../containers/product-catalog/routes/GlobalPricingRules'
import { VariationsGrid } from '../../containers/product-catalog/routes/VariationsGrid'
import { VariationCreate } from '../../containers/product-catalog/routes/VariationCreate'
import { VariationEdit } from '../../containers/product-catalog/routes/VariationsEdit'
import {
  AttributeDefinitionsList,
  CreateAttribute,
  AttributeDetails,
} from '../../containers/product-catalog/routes/AttributeDefinitions'

import { InventoryGrid } from '../../containers/inventory/routes/InventoryGrid'

import { ViewDiscountsGrid } from '../../containers/discounts/routes/ViewDiscountsGrid.tsx'
import { AddDiscountsRule } from '../../containers/discounts/routes/AddDiscountsRule.tsx'
import { EditDiscountsRule } from '../../containers/discounts/routes/EditDiscountsRule.tsx'

import { FacilitiesGrid } from '../../containers/supply-chain/routes/FacilitiesGrid/index.ts'
import { MasterProgramGridScreen } from '../../containers/supply-chain/routes/MasterProgramGrid/index.tsx'

import { SchedulePickup } from '../../containers/shipping/routes/SchedulePickup/SchedulePickup.tsx'

import { ViewClaimsGrid } from '../../containers/rma-vendor/routes/ViewClaimsGrid'
import { CreateClaim } from '../../containers/rma-vendor/routes/CreateClaim'
import { ViewClaim } from '../../containers/rma-vendor/routes/ViewClaim'

import { RmaShippingRules } from '../../containers/rma-shipping-rules/routes/RmaShippingRulesGrid/RmaShippingRulesScreen.tsx'

import { ViewLabelsGrid, EditLabel } from '../../containers/label-management/routes'

import { ViewSupplyLevelRulesGrid } from '../../containers/parts/routes/ViewSupplyLevelRulesGrid.tsx'
import { AddSupplyLevelRule } from '../../containers/parts/routes/AddSupplyLevelRule.tsx'
import { EditSupplyLevelRule } from '../../containers/parts/routes/EditSupplyLevelRule.tsx'

import { GoWholesaleRootScreen } from '../../containers/gowholesale/routes/gowholesale-root'
import { GoWholesaleNotificationsScreen } from '../../containers/gowholesale/routes/gowholesale-notifications'
import { GoWholesaleHomePageEditorScreen } from '../../containers/gowholesale/routes/gowholesale-home-page-editor'

import { OfferManagementOffersGridScreen } from '../../containers/offer-management/routes'

import { DepositManagementDepositsGridScreen } from '../../containers/deposit-management/routes'

import { SiteMap } from '../../containers/sitemap/routes/SiteMap'

import { ReportGroupsScreen, ReportGroupScreen } from '../../containers/reports/routes'

import { FMSIExportPresets } from '../Admin/FMSIExportPresets/FMSIExportPresets'
import { OrderProductPickUpSettings } from '../Admin/OrderProductPickupSettings/OrderProductPickupSettings'
import { ConditionMappings } from '../Admin/Metadata/ConditionMappingProfiles/ConditionMappingsProfilesGrid'
import EditProfileMappings from '../Admin/Metadata/ConditionMappings/ConditionMappingsGrid'
import { DecisionRulesGrid } from '../Admin/DecisionRulesGrid/DecisionRulesGrid'
import { CategoryMapping } from '../Admin/ProductCatalog/CategoryMapping'

import { PowerBiReport } from '../PowerBiReport'

import { ProductTemplateScreen } from '../ProductTemplate/ProductTemplateScreen'

import { ProductTemplatePricesScreen } from '../ProductTemplatePrices/ProductTemplatePricesScreen'
import { ProductTemplatePricesAnomaliesByRule } from '../ProductTemplatePrices/ProductTemplatePricesAnomaliesByRule'
import { ProductTemplatePricesAnomaliesByProduct } from '../ProductTemplatePrices/ProductTemplatePricesAnomaliesByProduct'

import { ProductCard } from '../ProductCard/ProductCard'

import { ConsolidationStepsScreen } from '../Consolidator/ConsolidationStepsScreen'

import { HomePage } from '../HomePage'
import { NoPermission } from '../NoPermission/NoPermission'
import { NotFound } from '../NotFound/NotFound'
import TrackingStatus from '../../containers/shipping/routes/TrackingStatus/TrackingStatus.tsx'
import { InitialPage } from './InitialPage'
import Authenticated from './Authenticated'

export default () => {
  const location = useLocation()

  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.setPage(location.pathname)
      window.analytics.sendPageView()
    }
  }, [location.pathname])

  return (
    <Routes>
      <Route
        exact
        path={ROUTES.R1_HOME}
        element={
          <Authenticated>
            <HomePage />
          </Authenticated>
        }
      />
      <Route exact path={ROUTES.R1} element={<Navigate to={ROUTES.R1_HOME} />} />
      <Route path={ROUTES.AUTH_REDIRECT} element={<Navigate to={ROUTES.R1_HOME} />} />

      <Route
        path={ROUTES.FMSI_EXPORT_PRESETS}
        element={
          <Authenticated>
            <FMSIExportPresets />
          </Authenticated>
        }
      />
      <Route
        path={ROUTES.ORDER_PRODUCT_PICKUP_SETTINGS}
        element={
          <Authenticated>
            <OrderProductPickUpSettings />
          </Authenticated>
        }
      />

      {/* Condition mappings */}
      <Route
        path={ROUTES.CONDITION_MAPPINGS}
        element={
          <Authenticated>
            <ConditionMappings />
          </Authenticated>
        }
      />
      <Route
        path={ROUTES.CONDITION_MAPPINGS_EDIT}
        element={
          <Authenticated>
            <EditProfileMappings />
          </Authenticated>
        }
      />

      <Route
        path={ROUTES.DECISION_GRID}
        element={
          <Authenticated>
            <DecisionRulesGrid />
          </Authenticated>
        }
      />
      <Route
        path={ROUTES.REPORTS_DASHBOARDS}
        element={
          <Authenticated>
            <ReportGroupsScreen />
          </Authenticated>
        }
      />
      <Route
        path={ROUTES.REPORTS_GROUP}
        element={
          <Authenticated>
            <ReportGroupScreen />
          </Authenticated>
        }
      />

      {/* External services profile management */}
      <Route
        exact
        path={ROUTES.PROFILE_EDIT}
        element={
          <Authenticated>
            <EditProfile />
          </Authenticated>
        }
      />
      <Route path={ROUTES.PROFILES}>
        <Route
          index
          element={
            <Authenticated>
              <PurposeList />
            </Authenticated>
          }
        />
        <Route
          exact
          path={ROUTES.PROFILES_LIST}
          element={
            <Authenticated>
              <ProfileList />
            </Authenticated>
          }
        />
        <Route
          exact
          path={ROUTES.PROFILE_CREATE}
          element={
            <Authenticated>
              <CreateProfile />
            </Authenticated>
          }
        />
        <Route
          exact
          path={ROUTES.PROFILE_CONFIGURATOR}
          element={
            <Authenticated>
              <ProfileConfigurator />
            </Authenticated>
          }
        />
        <Route path="*" element={<Navigate to={ROUTES.R1_NOT_FOUND} />} />
      </Route>

      {/* Product Catalog - Category Mapping */}
      <Route
        path={ROUTES.CATEGORY_MAPPING}
        element={
          <Authenticated>
            <CategoryMapping />
          </Authenticated>
        }
      />

      {/* GoWholesale  */}
      <Route path={ROUTES.GOWHOLESALE_ROOT}>
        <Route
          index
          element={
            <Authenticated>
              <GoWholesaleRootScreen />
            </Authenticated>
          }
        />
        <Route
          exact
          path={ROUTES.GOWHOLESALE_NOTIFICATIONS}
          element={
            <Authenticated>
              <GoWholesaleNotificationsScreen />
            </Authenticated>
          }
        />
        <Route
          exact
          path={ROUTES.GOWHOLESALE_HOME_PAGE_EDITOR}
          element={
            <Authenticated>
              <GoWholesaleHomePageEditorScreen />
            </Authenticated>
          }
        />
      </Route>

      {/* Tax commitment reporter */}
      <Route
        exact
        path={ROUTES.PURCHASE_ORDERS}
        element={
          <Authenticated>
            <PurchaseOrders />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.FINANCIAL_EVENTS}
        element={
          <Authenticated>
            <FinancialEvents />
          </Authenticated>
        }
      />

      {/* Product template and prices */}
      <Route
        exact
        path={ROUTES.PRODUCT_TEMPLATE}
        element={
          <Authenticated>
            <ProductTemplateScreen />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.PRODUCT}
        element={
          <Authenticated>
            <ProductCard />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.PRODUCT_TEMPLATE_PRICES}
        element={
          <Authenticated>
            <ProductTemplatePricesScreen />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.PRODUCT_TEMPLATE_PRICES_ANOMALIES_BY_RULE}
        element={
          <Authenticated>
            <ProductTemplatePricesAnomaliesByRule />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.PRODUCT_TEMPLATE_PRICES_ANOMALIES_BY_PRODUCT}
        element={
          <Authenticated>
            <ProductTemplatePricesAnomaliesByProduct />
          </Authenticated>
        }
      />

      <Route
        exact
        path={ROUTES.CONSOLIDATION_STEPS}
        element={
          <Authenticated>
            <ConsolidationStepsScreen />
          </Authenticated>
        }
      />

      <Route
        path={ROUTES.POWER_BI_REPORT}
        element={
          <Authenticated>
            <PowerBiReport />
          </Authenticated>
        }
      />

      <Route
        exact
        path={ROUTES.SHIPPINGS_SCHEDULE_PICKUP}
        element={
          <Authenticated>
            <SchedulePickup />
          </Authenticated>
        }
      />

      {/* Pricing Rules */}
      <Route
        exact
        path={ROUTES.PRICING_RULES}
        element={
          <Authenticated>
            <PricingRules />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.GLOBAL_PRICING_RULES}
        element={
          <Authenticated>
            <GlobalPricingRules />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.GLOBAL_PRICING_RULES_DETAILS}
        element={
          <Authenticated>
            <GlobalPricingRuleView />
          </Authenticated>
        }
      />

      {/* Supply Chain */}
      <Route
        exact
        path={ROUTES.MASTER_PROGRAMS}
        element={
          <Authenticated>
            <MasterProgramGridScreen />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.FACILITIES}
        element={
          <Authenticated>
            <FacilitiesGrid />
          </Authenticated>
        }
      />

      {/* User Management */}
      <Route
        exact
        path={ROUTES.USERS}
        element={
          <Authenticated>
            <ViewUsersGrid />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.USER_CREATE}
        element={
          <Authenticated>
            <CreateUser />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.USER_DUPLICATE}
        element={
          <Authenticated>
            <CreateUser />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.USER_EDIT}
        element={
          <Authenticated>
            <EditUser />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.ROLES}
        element={
          <Authenticated>
            <RolesGrid />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.ROLE_PAGE}
        element={
          <Authenticated>
            <RolePage />
          </Authenticated>
        }
      />

      {/* Product Catalog */}
      <Route
        exact
        path={ROUTES.CATEGORIES}
        element={
          <Authenticated>
            <ViewCategoriesGrid />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.CATEGORY_CREATE}
        element={
          <Authenticated>
            <CategoryCreate />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.CATEGORY_DETAILS}
        element={
          <Authenticated>
            <CategoryDetails />
          </Authenticated>
        }
      />

      <Route
        exact
        path={ROUTES.PRODUCTS}
        element={
          <Authenticated>
            <ViewProductsGrid />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.PRODUCT_DETAILS}
        element={
          <Authenticated>
            <ProductDetails />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.PRODUCT_VIEW}
        element={
          <Authenticated>
            <ProductView />
          </Authenticated>
        }
      />

      <Route
        exact
        path={ROUTES.ATTRIBUTES_DEFINITIONS}
        element={
          <Authenticated>
            <AttributeDefinitionsList />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.ATTRIBUTE_DEFINITION_CREATE}
        element={
          <Authenticated>
            <CreateAttribute />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.ATTRIBUTE_DEFINITION_DETAILS}
        element={
          <Authenticated>
            <AttributeDetails />
          </Authenticated>
        }
      />

      <Route
        exact
        path={ROUTES.VARIATIONS}
        element={
          <Authenticated>
            <VariationsGrid />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.VARIATIONS_CREATE}
        element={
          <Authenticated>
            <VariationCreate />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.VARIATIONS_EDIT}
        element={
          <Authenticated>
            <VariationEdit />
          </Authenticated>
        }
      />

      {/* RMA */}
      <Route
        exact
        path={ROUTES.CLAIMS_B2C}
        element={
          <Authenticated>
            <ViewClaimsGrid claimType="B2C" />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.CLAIMS_B2B}
        element={
          <Authenticated>
            <ViewClaimsGrid claimType="B2B" />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.CLAIM_CREATE_B2C}
        element={
          <Authenticated>
            <CreateClaim claimType="B2C" />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.CLAIM_CREATE_B2B}
        element={
          <Authenticated>
            <CreateClaim claimType="B2B" />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.CLAIM_VIEW_B2C}
        element={
          <Authenticated>
            <ViewClaim claimType="B2C" />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.CLAIM_VIEW_B2B}
        element={
          <Authenticated>
            <ViewClaim claimType="B2B" />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.RMA_SHIPPING_RULES}
        element={
          <Authenticated>
            <RmaShippingRules />
          </Authenticated>
        }
      />

      <Route
        exact
        path={ROUTES.UNITS}
        element={
          <Authenticated>
            <InventoryGrid />
          </Authenticated>
        }
      />

      {/* Label Management */}
      <Route path={ROUTES.LABEL_MANAGEMENT}>
        <Route
          index
          element={
            <Authenticated>
              <ViewLabelsGrid />
            </Authenticated>
          }
        />
        <Route
          exact
          path={ROUTES.LABEL_EDIT}
          element={
            <Authenticated>
              <EditLabel />
            </Authenticated>
          }
        />
        <Route path="*" element={<Navigate to={ROUTES.R1_NOT_FOUND} />} />
      </Route>

      {/* Discount Rules */}
      <Route path={ROUTES.DISCOUNTS}>
        <Route
          index
          element={
            <Authenticated>
              <ViewDiscountsGrid />
            </Authenticated>
          }
        />
        <Route
          exact
          path={ROUTES.DISCOUNT_CREATE}
          element={
            <Authenticated>
              <AddDiscountsRule />
            </Authenticated>
          }
        />
        <Route
          exact
          path={ROUTES.DISCOUNT_EDIT}
          element={
            <Authenticated>
              <EditDiscountsRule />
            </Authenticated>
          }
        />
        <Route path="*" element={<Navigate to={ROUTES.R1_NOT_FOUND} />} />
      </Route>

      {/* Supply Level Rules */}
      <Route
        exact
        path={ROUTES.SUPPLY_LEVEL_RULES}
        element={
          <Authenticated>
            <ViewSupplyLevelRulesGrid />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.SUPPLY_LEVEL_RULE_CREATE}
        element={
          <Authenticated>
            <AddSupplyLevelRule />
          </Authenticated>
        }
      />
      <Route
        exact
        path={ROUTES.SUPPLY_LEVEL_RULE_EDIT}
        element={
          <Authenticated>
            <EditSupplyLevelRule />
          </Authenticated>
        }
      />

      {/* Go Wholesale */}
      <Route
        path={ROUTES.OFFER_MANAGEMENT}
        element={
          <Authenticated>
            <OfferManagementOffersGridScreen />
          </Authenticated>
        }
      />
      <Route
        path={ROUTES.DEPOSIT_MANAGEMENT}
        element={
          <Authenticated>
            <DepositManagementDepositsGridScreen />
          </Authenticated>
        }
      />

      <Route
        path={ROUTES.SITEMAP}
        element={
          <Authenticated>
            <SiteMap />
          </Authenticated>
        }
      />

      <Route
        exact
        path="/"
        element={
          <Authenticated>
            <InitialPage />
          </Authenticated>
        }
      />
      {/**
       * Route that will be rendered if redirect to <ROUTES.NO_PERMISSION> was happened.
       * Useful when server sent 403 status on main request and need redirect user to 403 page.
       */}
      <Route
        exact
        path={ROUTES.R1_NO_PERMISSION}
        element={
          <Authenticated>
            <NoPermission />
          </Authenticated>
        }
      />

      {/**
       * Pages without authentication
       */}

      <Route path={ROUTES.SHIPPINGS_TRACKING_STATUS} element={<TrackingStatus />} />

      {/**
       * Route that will be rendered if redirect to <ROUTES.R1_NOT_FOUND> was happened.
       * Useful when server sent 404 status on main request and need redirect user to 404 page.
       */}
      <Route exact path={ROUTES.R1_NOT_FOUND} element={<NotFound />} />
      {/**
       * Route that will be rendered if user has inputted incorrect url.
       * E.g. https://<domaine_name>/blablabla
       */}
      <Route path="*" element={<Navigate to={ROUTES.R1_NOT_FOUND} />} />
    </Routes>
  )
}
