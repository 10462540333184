import React from 'react'
import { Offer, OfferPreview } from '@r1-webui/gowholesale-offermanagement-v1'
import { StructuredListColumnSettings } from '@r1/ui-kit/contracts/ts/StructuredList'
import { Link, Text } from '@r1/ui-kit'
import { ROUTES } from '../../../../../../navigation'
import { OFFERS_FILTER_OFFER_ID_QUERY_KEY } from '../../../../const'

export const validatePrice = (price: number): string | null => {
  if (price <= 0) {
    return 'Counter offer price must be higher than 0'
  }
  return null
}

export const validateQuantity = (quantity: number, currentOffer: Offer): string | null => {
  if (quantity <= 0) {
    return 'Counter offer quantity must be higher than 0'
  }
  if (quantity > currentOffer.statistic.targetStock.quantity.value) {
    return `The maximum number of products is ${currentOffer.statistic.targetStock.quantity.value}`
  }
  return null
}

export const getCounterConfirmMessage = ({
  quantity,
  price,
  currentOffer,
}: {
  quantity: number
  price: number
  currentOffer: Offer
}): JSX.Element => {
  return (
    <>
      <Text type="paragraph">
        You are countering an offer of {price} and quantity ${quantity} on product{' '}
        {currentOffer.product.name} that has a current offer of {currentOffer.price.amount} and
        quantity {currentOffer.quantity.value}.
      </Text>
      <Text type="paragraph">Are you sure?</Text>
    </>
  )
}

export const getCounterAllConfirmMessage = ({
  price,
  quantity,
  currentOffer,
}: {
  price: number
  quantity: number
  currentOffer: Offer
}): JSX.Element => {
  return (
    <>
      <Text type="paragraph">
        You are countering all offers of ${price} and quantity {quantity} on product{' '}
        {currentOffer.product.name}.
      </Text>
      <Text type="paragraph">Are you sure?</Text>
    </>
  )
}

export const getOtherOffersColumnsSettings = (): StructuredListColumnSettings<OfferPreview>[] => {
  const searchParams = new URLSearchParams()

  return [
    {
      header: 'Offer ID',
      cell: {
        $type: 'custom',
        renderer: ({ id: offerId }: OfferPreview) => {
          searchParams.set(OFFERS_FILTER_OFFER_ID_QUERY_KEY, offerId)
          return (
            <Link target="_blank" href={`${ROUTES.OFFER_MANAGEMENT}?${searchParams.toString()}`}>
              {offerId}
            </Link>
          )
        },
      },
    },
    {
      header: 'Offer Price',
      cell: {
        $type: 'custom',
        renderer: ({ price }: OfferPreview) => <Text>&#36;{price.amount}</Text>,
      },
    },
    {
      header: 'Offer Quantity',
      cell: {
        $type: 'custom',
        renderer: ({ quantity }: OfferPreview) => <Text>{quantity.value}</Text>,
      },
    },
    {
      header: 'Counter Offer',
      cell: {
        $type: 'custom',
        renderer: ({ askPrice }: OfferPreview) =>
          askPrice ? <Text>&#36;{askPrice.amount}</Text> : <Text>-</Text>,
      },
    },
  ]
}
