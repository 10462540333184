import * as React from 'react'
import { Control, FieldErrors } from 'react-hook-form'
import { Collapse } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import { Facility } from '@r1-webui/facilities-v1'
import { MainSettings } from '../FacilityFields/MainSettings'
import { Addresses } from '../FacilityFields/Addresses'
import { Schedule } from '../FacilityFields/Schedule'
import { NonWorkingDays } from '../FacilityFields/NonWorkingDays'
import { Shifts } from '../FacilityFields/Shifts'
import { ShippingCutOffTimes } from '../FacilityFields/ShippingCutOffTimes'
import { useCountries } from '../../hooks/useCountries'
import { useTimezones } from '../../hooks/useTimezones'
import { defaultActivePanels, defaultEditAdresses } from '../../constants'

export const FacilityForm = ({
  facility,
  control,
  errors,
}: {
  facility: Facility | null
  control: Control
  errors: FieldErrors<Facility>
}) => {
  const [{ allowFacilityEdit }] = useAccessControl()
  const { countries } = useCountries()
  const { timezones } = useTimezones()

  const adresses = facility ? facility?.addresses : defaultEditAdresses

  const [activePanels, setActivePanels] = React.useState<Array<string>>(defaultActivePanels)

  return (
    <Collapse active={activePanels} onChange={setActivePanels}>
      <Collapse.Panel id="mainSettings" title="Main Settings">
        <MainSettings
          editable={allowFacilityEdit}
          control={control}
          errors={errors}
          name={facility?.name}
          accountingName={facility?.accountingName}
          timezones={timezones}
          timezoneId={facility?.timezoneId}
          numberOfGates={facility?.numberOfGates}
          numberOfLocalPickupGates={facility?.numberOfLocalPickupGates}
        />
      </Collapse.Panel>
      <Collapse.Panel id="addresses" title="Addresses">
        <Addresses
          editable={allowFacilityEdit}
          control={control}
          addresses={adresses}
          countries={countries}
        />
      </Collapse.Panel>
      <Collapse.Panel id="schedule" title="Warehouse Pickup Hours">
        <Schedule editable={allowFacilityEdit} control={control} schedule={facility?.schedule} />
      </Collapse.Panel>
      <Collapse.Panel id="nonWorkingDays" title="Non-Working Days">
        <NonWorkingDays
          editable={allowFacilityEdit}
          control={control}
          nonWorkingDays={facility?.nonWorkingDays}
        />
      </Collapse.Panel>
      <Collapse.Panel id="shifts" title="Teammate Work Shifts">
        <Shifts editable={allowFacilityEdit} control={control} shifts={facility?.shifts} />
      </Collapse.Panel>
      <Collapse.Panel id="shippingCutOffTimes" title="Shipping Cut-Off Times">
        <ShippingCutOffTimes
          editable={allowFacilityEdit}
          control={control}
          shippingCutOffTimes={facility?.shippingCutOffTimes}
        />
      </Collapse.Panel>
    </Collapse>
  )
}
