// @flow

export const R1 = '/r1'
export const ROOT = '/r1v2'
export const R1_HOME = `${R1}/home`
export const LOGIN = '/login'
export const AUTH_REDIRECT = `${R1}/auth/redirect`
const R1_ADMIN = `${R1}/admin`
const ADMIN = `${ROOT}/admin`
const METADATA = `${R1_ADMIN}/metadata`

/* Administrative */
export const FMSI_EXPORT_PRESETS = `${R1_ADMIN}/fmsi/export/:id`
export const ORDER_PRODUCT_PICKUP_SETTINGS = `${R1_ADMIN}/marketplaceSettings/orderProductPickUpSettings`
export const CONDITION_MAPPINGS = `${METADATA}/marketplaceConditionMappings`
export const CONDITION_MAPPINGS_EDIT = `${METADATA}/editConditionMappings/:id`
export const DECISION_GRID = `${R1_ADMIN}/decision-grid`
export const REPORTS_DASHBOARDS = `${R1_ADMIN}/reports-dashboards`
export const REPORTS_GROUP = `${R1_ADMIN}/reports-dashboards/:reportGroupId`
export const CATEGORY_MAPPING = `${R1_ADMIN}/productCatalog/categoryMapping`

/* Administrative - ESPM */
export const PROFILES = `${R1_ADMIN}/profiles`
export const PROFILE_EDIT = `${R1_ADMIN}/profile/:id`
export const PROFILES_LIST = `${PROFILES}/:purposeId`
export const PROFILE_CREATE = `${PROFILES_LIST}/:profileTypeId/:type`
export const PROFILE_CONFIGURATOR = `${PROFILES}/tax/mapping`

/* Administrative - GoWholesale */
export const GOWHOLESALE_ROOT = `${R1_ADMIN}/gowholesale`
export const GOWHOLESALE_NOTIFICATIONS = `${GOWHOLESALE_ROOT}/notifications`
export const GOWHOLESALE_HOME_PAGE_EDITOR = `${GOWHOLESALE_ROOT}/homepage-editor`

/* Tax Commitment Reporter */
export const PURCHASE_ORDERS = `${R1}/tax/purchaseOrders`
export const FINANCIAL_EVENTS = `${R1}/tax/financialEvents`

/* Product Template and Prices */
export const PRODUCT_TEMPLATE = `${R1}/producttemplate/:id`
export const PRODUCT = `${R1}/product/:id`
export const PRODUCT_TEMPLATE_PRICES = `${R1}/product-prices`
export const PRODUCT_TEMPLATE_PRICES_ANOMALIES_BY_RULE = `${R1}/product-prices-anomalies-rule`
export const PRODUCT_TEMPLATE_PRICES_ANOMALIES_BY_PRODUCT = `${R1}/product-prices-anomalies-product`

/* User Management */
const USER_MANAGEMENT = `${ADMIN}/user-management`

const PREFIX_USERS = '/users'
export const USERS = `${USER_MANAGEMENT}${PREFIX_USERS}`
export const USER_CREATE = `${USERS}/new`
export const USER_DUPLICATE = `${USERS}/copy/:userId`
export const USER_EDIT = `${USERS}/:userId`

const PREFIX_ROLES = '/roles'
export const ROLES = `${USER_MANAGEMENT}${PREFIX_ROLES}`
export const ROLE_PAGE = `${ROLES}/:roleId`

/* Product Catalog */
const PRODUCT_CATALOG = `${ROOT}/product-catalog`
export const ATTRIBUTES_DEFINITIONS = `${PRODUCT_CATALOG}/attribute-definitions`
export const ATTRIBUTE_DEFINITION_CREATE = `${ATTRIBUTES_DEFINITIONS}/new`
export const ATTRIBUTE_DEFINITION_DETAILS = `${ATTRIBUTES_DEFINITIONS}/:attributeId`
export const PRODUCTS = `${PRODUCT_CATALOG}/products`
export const PRODUCT_DETAILS = `${PRODUCTS}/:productId`
export const PRODUCT_VIEW = `${PRODUCTS}/:productId/view` // direct link only for tests
export const CATEGORIES = `${PRODUCT_CATALOG}/categories`
export const CATEGORY_CREATE = `${CATEGORIES}/new`
export const CATEGORY_DETAILS = `${CATEGORIES}/:categoryId`
export const VARIATIONS = `${PRODUCT_CATALOG}/variations`
export const VARIATIONS_CREATE = `${VARIATIONS}/new`
export const VARIATIONS_EDIT = `${VARIATIONS}/:variationId`

/* Pricing Rules */
export const PRICING_RULES = `${ROOT}/pricing-rules`
export const GLOBAL_PRICING_RULES = `${ROOT}/aggregated-pricing-rules`
export const GLOBAL_PRICING_RULES_DETAILS = `${ROOT}/aggregated-pricing-rules/:pricingRuleId`

/* RMA */
const RETURNS = `${ROOT}/returns`
export const CLAIMS_B2C = `${RETURNS}/b2c/claims`
export const CLAIMS_B2B = `${RETURNS}/b2b/claims`
export const CLAIM_CREATE_B2C = `${CLAIMS_B2C}/new`
export const CLAIM_CREATE_B2B = `${CLAIMS_B2B}/new`
export const CLAIM_VIEW_B2C = `${CLAIMS_B2C}/:claimId`
export const CLAIM_VIEW_B2B = `${CLAIMS_B2B}/:claimId`
export const RMA_SHIPPING_RULES = `${ROOT}/rma-shipping-rules`

/* Inventory */
const INVENTORY = `${ROOT}/inventory`
export const UNITS = `${INVENTORY}/units`

/* Label Management */
export const LABEL_MANAGEMENT = `${ROOT}/label-management`
export const LABEL_CREATE = `${LABEL_MANAGEMENT}/new`
export const LABEL_EDIT = `${LABEL_MANAGEMENT}/:templateId`

/* Discount Rules */
export const DISCOUNTS = `${ROOT}/sales/discounts/rules`
export const DISCOUNT_CREATE = `${DISCOUNTS}/new`
export const DISCOUNT_EDIT = `${DISCOUNTS}/:discountId`

/* Supply Level Rules */
const PARTS = `${ROOT}/parts`
export const SUPPLY_LEVEL_RULES = `${PARTS}/supply-level-rules`
export const SUPPLY_LEVEL_RULE_CREATE = `${SUPPLY_LEVEL_RULES}/new`
export const SUPPLY_LEVEL_RULE_EDIT = `${SUPPLY_LEVEL_RULES}/:supplyLevelRuleId`

/* GoWholesale */
export const OFFER_MANAGEMENT = `${ADMIN}/sales/gowholesale-offer-management`
export const DEPOSIT_MANAGEMENT = `${ADMIN}/sales/gowholesale-deposit-management`

/* Shippings */
const SHIPPINGS = `${R1}/shippings`
export const SHIPPINGS_SCHEDULE_PICKUP = `${SHIPPINGS}/schedule-pickup`
export const SHIPPINGS_TRACKING_STATUS = `${SHIPPINGS}/trackingStatus/:trackingNumber`

/* Supply Chain */
const SUPPLY_CHAIN = `${R1}/supply-chain`
export const MASTER_PROGRAMS = `${SUPPLY_CHAIN}/master-programs`
export const FACILITIES = `${SUPPLY_CHAIN}/warehouses/facilities`

/* Other */
export const CONSOLIDATION_STEPS = `${R1}/consolidation-steps`
export const POWER_BI_REPORT = `${R1}/power-bi-report`

/* Sitemap */
export const SITEMAP = `${R1}/sitemap`

/* No permission */
export const R1_NO_PERMISSION = `${R1}/no-permission`
export const NO_PERMISSION = `${ROOT}/no-permission`

/* Not found */
export const R1_NOT_FOUND = `${R1}/not-found`
export const NOT_FOUND = `${ROOT}/not-found`
