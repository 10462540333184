/* eslint-disable @typescript-eslint/no-floating-promises */
import { Format, Flex, H2, Box, NotificationSystem } from '@r1/ui-kit'
import { Table, DisplaySettings } from '@r1/grid'
import { useParams } from 'react-router-dom'
import * as React from 'react'

import { TrackingInfo, TrackingStatus } from '@r1-webui/shipping-trackingstatus-v1'
import { trackingStatusApi } from '../../../../api/shippingCarriers/trackingStatus'
import { carriersApi } from '../../../../api/shippingCarriers/carriers'

export default () => {
  const params = useParams()
  const [trackingInfo, setTrackingInfo] = React.useState<TrackingInfo | null>(null)
  const [shippingService, setShippingService] = React.useState<string | null>(null)

  React.useEffect(() => {
    const fetchTrackingInfo = async () => {
      try {
        const response = await trackingStatusApi.getTrackingStatus({
          trackingNumber: params.trackingNumber || '',
        })
        if (response.status === 200) {
          setTrackingInfo(response.body)

          if (response.body.serviceCode) {
            const shippingServiecResponse =
              await carriersApi.getCarriersShippingServiceByServiceCode({
                serviceCode: response.body.serviceCode,
              })
            if (
              shippingServiecResponse.status === 200 &&
              shippingServiecResponse.body.names.length > 0
            ) {
              setShippingService(shippingServiecResponse.body.names[0].name?.replace('_', ' '))
            }
          }
        } else {
          NotificationSystem.addNotification({
            level: 'error',
            title: `Error ${response.status}`,
            message: 'Unhandled error',
          })
          setTrackingInfo({ carrier: '', serviceCode: '', proNumber: '', statuses: [] })
        }
      } catch {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Error',
          message: 'Unhandled error',
        })
        setTrackingInfo({ carrier: '', serviceCode: '', proNumber: '', statuses: [] })
      }
    }
    fetchTrackingInfo()
  }, [params.trackingNumber])

  const displaySettings = React.useMemo<DisplaySettings<TrackingStatus>>(
    () => ({
      columnsSettings: [
        {
          header: {
            content: 'Status Date',
          },
          cell: {
            $type: 'custom',
            renderer: ({ occurredOn }) => <Format.DateTime>{occurredOn}</Format.DateTime>,
          },
        },
        {
          header: {
            content: 'Activity',
          },
          cell: {
            $type: 'text',
            field: 'activity',
          },
        },
        {
          header: {
            content: 'Status Code Description',
          },
          cell: {
            $type: 'text',
            field: 'status',
          },
        },
        {
          header: {
            content: 'Current City',
          },
          cell: {
            $type: 'text',
            field: 'city',
          },
        },
        {
          header: {
            content: 'Current State',
          },
          cell: {
            $type: 'text',
            field: 'state',
          },
        },
        {
          header: {
            content: 'Expected Arrival Date',
          },
          cell: {
            $type: 'custom',
            renderer: ({ expectedArrival }) => <Format.DateTime>{expectedArrival}</Format.DateTime>,
          },
        },
        {
          header: {
            content: 'Expected Departure Date',
          },
          cell: {
            $type: 'custom',
            renderer: ({ expectedDeparture }) => (
              <Format.DateTime>{expectedDeparture}</Format.DateTime>
            ),
          },
        },
        {
          header: {
            content: 'Actual Departure Date',
          },
          cell: {
            $type: 'custom',
            renderer: ({ actualDeparture }) => <Format.DateTime>{actualDeparture}</Format.DateTime>,
          },
        },
      ],
    }),
    [],
  )

  return (
    <Flex column m="XL" maxWidth={1}>
      <Box>
        <H2>BOL # - {params.trackingNumber}</H2>
      </Box>
      {trackingInfo?.proNumber && (
        <Box>
          <H2>{`Pro Number - ${trackingInfo.proNumber}`}</H2>
        </Box>
      )}
      {shippingService && (
        <Box>
          <H2>{`Carrier - ${shippingService}`}</H2>
        </Box>
      )}
      <Box grow>
        <Table
          resizable
          suppressVirtualization
          fullscreenMode="page"
          loading={!trackingInfo}
          data={trackingInfo?.statuses || []}
          displaySettings={displaySettings}
        />
      </Box>
    </Flex>
  )
}
