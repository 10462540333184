import { NotificationSystem } from '@r1/ui-kit'
import {
  WarehousePickupTimeDescriptionPerDate,
  Warehouse,
  PickupDescription,
  Pickup,
  Order,
  PickupType,
} from '@r1-webui/facilitypickupscheduler-v1'
import { handleServerError } from '@r1/core-blocks'
import { addYears, formatISO, startOfDay, endOfDay } from 'date-fns'

import { schedulePickupApi } from '../../../api/schedulePickup/pickup'
import { replaceTemplateValues } from '../../../utils/responseUtils'

export const getPickupDescriptions = async (
  warehouseLocationId: string,
  orderIds: string[] = [],
): Promise<WarehousePickupTimeDescriptionPerDate[]> => {
  const startOfToday = startOfDay(new Date())
  const endofToday = endOfDay(new Date())
  const startTime = formatISO(addYears(startOfToday, -1))
  const endTime = formatISO(addYears(endofToday, 1))

  const res = await schedulePickupApi.searchPickupDescriptions(
    { locationId: warehouseLocationId },
    {
      startTime,
      endTime,
      orderIds,
    },
  )
  if (res.status !== 200) {
    handleServerError(res)
    return []
  }

  return res.body
}

export type CreatePickupSingleParams = {
  warehouseLocationId: string
  orderId: string
  pickupDate: string
  timeStart: string
  timeEnd: string
}

export const createPickupSingle = async (
  params: CreatePickupSingleParams,
): Promise<PickupDescription | null> => {
  const period = { start: params.timeStart, end: params.timeEnd }

  const res = await schedulePickupApi.createPickupSingle(
    { locationId: params.warehouseLocationId },
    {
      companyOrderId: params.orderId,
      pickupDate: params.pickupDate,
      period,
    },
  )

  const result = res.status === 200 ? res.body : null

  if (res.status === 403) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'You have no permission to create pickup',
    })
  } else if (res.status !== 200) {
    handleServerError(res)
  } else {
    NotificationSystem.addNotification({
      level: 'success',
      title: 'Pickup has been created',
    })
  }

  return result
}

export const getWarehouseLocations = async (): Promise<Warehouse[]> => {
  const res = await schedulePickupApi.getWarehouses()
  if (res.status !== 200) {
    handleServerError(res)
    return []
  }

  return res.body
}

export interface UpdatePickupTimeParams {
  pickupDate: string
  period: { start: string; end: string }
}

export const updatePickupTime = async (
  warehouseId: string,
  pickUpId: string,
  pickupType: PickupType,
  args: UpdatePickupTimeParams,
): Promise<boolean> => {
  const res = await schedulePickupApi.updatePickupTime(
    { locationId: warehouseId, pickupId: pickUpId, pickupType },
    {
      pickupDate: args.pickupDate,
      period: { start: args.period.start, end: args.period.end },
    },
  )

  if (res.status === 403) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'You have no permission to update pickup time',
    })
    return false
  }

  if (res.status !== 200) {
    handleServerError(res)
    return false
  }

  NotificationSystem.addNotification({ level: 'success', title: 'Pickup time has been updated' })
  return true
}

export const deletePickup = async (
  warehouseLocationId: string,
  pickupId: string,
  pickupType: PickupType,
): Promise<boolean> => {
  const res = await schedulePickupApi.deleteOrderPickup({
    locationId: warehouseLocationId,
    pickupId,
    pickupType,
  })

  if (res.status === 403) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'You have no permission to delete pickup',
    })
    return false
  }

  if (res.status !== 200) {
    handleServerError(res)
    return false
  }

  NotificationSystem.addNotification({
    level: 'success',
    title: 'Pickup has been deleted',
  })

  return true
}

export const getOrderPickup = async (
  warehouseLocationId: string,
  pickupId: string,
  pickupType: PickupType,
): Promise<Pickup | null> => {
  const res = await schedulePickupApi.getOrderPickup({
    locationId: warehouseLocationId,
    pickupId,
    pickupType,
  })

  if (res.status === 200) {
    return res.body
  }

  handleServerError(res)
  return null
}

export type PickupBundleParams = {
  orderIds: string[]
  pickupDate: string
  timeStart: string
  timeEnd: string
}

export const createPickupBundle = async (
  warehouseId: string,
  params: PickupBundleParams,
): Promise<boolean> => {
  const period = { start: params.timeStart, end: params.timeEnd }

  const res = await schedulePickupApi.createPickupBundle(
    { locationId: warehouseId },
    {
      companyOrderIds: params.orderIds,
      period,
      pickupDate: params.pickupDate,
    },
  )

  if (res.status !== 200) {
    if (res.status === 403) {
      NotificationSystem.addNotification({
        level: 'error',
        title: 'You have no permission to create bundle pickup',
      })
    }
    if (res.status === 422) {
      NotificationSystem.addNotification({
        level: 'error',
        title: replaceTemplateValues(res.body),
      })
    } else {
      handleServerError(res)
    }

    return false
  }

  NotificationSystem.addNotification({
    level: 'success',
    title: 'Bundle pickup has been created',
  })
  return true
}

export const getBundleCandidates = async (
  warehouseId: string,
  orderId: string,
): Promise<Order[]> => {
  const res = await schedulePickupApi.findFittedOrdersForBundle(
    {
      locationId: warehouseId,
    },
    { orderId },
  )

  if (res.status !== 200) {
    handleServerError(res)
    return []
  }
  return res.body
}

export type CreatePickupLocalB2CParams = {
  warehouseLocationId: string
  orderId: string
  pickupDate: string
  timeStart: string
  timeEnd: string
}

export const createPickupLocalB2C = async (
  params: CreatePickupLocalB2CParams,
): Promise<PickupDescription | null> => {
  const period = { start: params.timeStart, end: params.timeEnd }

  const res = await schedulePickupApi.createPickupLocalB2C(
    { locationId: params.warehouseLocationId },
    {
      companyOrderId: params.orderId,
      pickupDate: params.pickupDate,
      period,
    },
  )

  const result = res.status === 200 ? res.body : null

  if (res.status === 403) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'You have no permission to create pickup',
    })
  } else if (res.status !== 200) {
    handleServerError(res)
  } else {
    NotificationSystem.addNotification({
      level: 'success',
      title: 'Pickup has been created',
    })
  }

  return result
}
