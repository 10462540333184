import {
  INavigationApiService,
  INavigationItem,
} from '@r1/wireframe-primary/typings/WireframeProvider/types/NavigationApiService'
import { NavigationApiService } from '@r1/wireframe-primary'
import { useUserInfo } from '../../../utils/useUserInfo'
import { httpClient } from '../../../httpClient'
import { getRMPMenuItemsByPermissions } from '../mocks/rmpMenuItems'
import { isRMP } from '../utils'
import { humanizeString } from '../../../utils/humanizeString'

export type INavigationItemWithRequiredPermissions = {
  permissions?: Array<string>
} & INavigationItem

const PRODUCT_CATALOG_ITEM_ID = '8'
const SECONDARY_CATALOGS_ITEM = {
  id: 'catalogs',
  parentId: PRODUCT_CATALOG_ITEM_ID,
  title: 'Catalogs',
  description: 'Main and secondary catalogs',
  // url: '/app/product-catalog/catalogs',
  isStarred: false,
}

type Catalog = {
  id: string
  name: string
  description: string
  draft: boolean
  companyId: number
}

export type INavigationApiServiceWithRequiredPermissions = Pick<
  INavigationApiService,
  'addToStarred' | 'deleteFromStarred'
> & {
  getMenuItems: (programId: number | null) => Promise<Array<INavigationItemWithRequiredPermissions>>
}

function generateSecondaryCatalogMenuSection(
  secondaryCatalog: Catalog,
): INavigationItemWithRequiredPermissions[] {
  return [
    {
      id: secondaryCatalog.id,
      parentId: SECONDARY_CATALOGS_ITEM.id,
      title: humanizeString(secondaryCatalog.name),
      description: secondaryCatalog.name,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-families`,
      parentId: secondaryCatalog.id,
      title: 'Families',
      description: '',
      url: `/app/product-catalog/catalogs/${secondaryCatalog.id}/families`,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-templates`,
      parentId: secondaryCatalog.id,
      title: 'Templates',
      description: '',
      url: `/app/product-catalog/catalogs/${secondaryCatalog.id}/templates`,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-sftp-connections`,
      parentId: secondaryCatalog.id,
      title: 'SFTP connections',
      description: '',
      url: `/app/product-catalog/catalogs/${secondaryCatalog.id}/sftp-connections`,
      isStarred: false,
      permissions: ['CatalogImportSFTPSettings:View'],
    },
  ]
}

export const useNavigationApi = () => {
  const originalNavigationApi = new NavigationApiService(httpClient)
  const {
    company: { type },
  } = useUserInfo()
  const getR1MenuItems = async (programId: number | null) => {
    const originalMenuItems = await originalNavigationApi.getMenuItems(programId)
    let secondaryCatalogs: INavigationItemWithRequiredPermissions[] = []

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      const response: Array<Catalog> = (await httpClient.get(
        '/api/catalog-view/catalogs/secondary',
      )) as Array<Catalog>

      if (Array.isArray(response)) {
        secondaryCatalogs = response.reduce<INavigationItemWithRequiredPermissions[]>(
          (catalogs, secondaryCatalog) => {
            if (secondaryCatalog?.draft) {
              return catalogs
            }
            const secondaryCatalogSection = generateSecondaryCatalogMenuSection(secondaryCatalog)
            return [...catalogs, ...secondaryCatalogSection]
          },
          [],
        )
      }
      return originalMenuItems.concat([SECONDARY_CATALOGS_ITEM]).concat(secondaryCatalogs)
    } catch (e) {
      console.info(e)
      return originalMenuItems
    }
  }

  const getRmpMenuItems = () => getRMPMenuItemsByPermissions(type)
  return {
    getMenuItems: isRMP ? getRmpMenuItems : getR1MenuItems,
    addToStarred: originalNavigationApi.addToStarred,
    deleteFromStarred: originalNavigationApi.deleteFromStarred,
  }
}
