import { Customer } from '@r1-webui/customerprovider-v1'
import {
  Offer as OfferType,
  OfferEvent,
  OfferFilter,
} from '@r1-webui/gowholesale-offermanagement-v1'
import { GridPagination, OfferToCounter } from '../../types'

export enum OffersGridHeaderType {
  Id,
  CompanyName,
  CompanyPhone,
  Product,
  Sku,
  Upc,
  Ask,
  Status,
  InitialOffer,
  Offer,
  OfferUnits,
  OfferExtendedValue,
  HighestActiveOffer,
  LowestActiveOffer,
  Facility,
  RemainingUnits,
  LastSale,
  Recovery,
  ExpirationDate,
  BuyersCounterOffer,
  SellersCounterOffer,
  SellersCounterOfferUnits,
  OrderId,
  ApprovedDate,
  ApprovedBy,
  LastTwoWeekAverageProductPrice,
  LastTwoWeekUnitsSold,
  Actions,
  CancellationDate,
  ActiveBasketSize,
}

export enum OffersGridDialogCase {
  ConfirmOffersApproval,
  ConfirmOffersReject,
  ConfirmApprovalWithHigherSimilar,
  ConfirmCounterOfferWithHigherSimilar,
  ShowApprovalError,
  ShowRejectError,
}

export type CounterOfferData = {
  currentOffer: OfferType
  counterPrice: number
  counterQuantity: number
}

export type OffersGridProps = {
  isFetchingData: boolean
  isExportInProgress: boolean
  data: OfferType[]
  total: number
  pagination: GridPagination
  filter: OfferFilter
  offerForView: OfferType | null
  isOfferDrawerOpen: boolean
  onChangeFilter: (filters: OfferFilter) => void
  onApproveOffers: (offers: OfferType[]) => Promise<void>
  onRejectOffers: (offers: OfferType[]) => Promise<void>
  onExportOffers: () => Promise<void>
  onCounterOffer: ({
    offerToCounter,
    counterPrice,
    counterQuantity,
  }: {
    offerToCounter: OfferToCounter
    counterPrice: number
    counterQuantity: number
  }) => Promise<void>
  onCounterMultipleOffers: ({
    offersToCounter,
    counterPrice,
    counterQuantity,
  }: {
    offersToCounter: OfferToCounter[]
    counterPrice: number
    counterQuantity: number
  }) => Promise<void>
  onRequestOfferHistory: (offer: OfferType) => Promise<OfferEvent[]>
  onChangePagination: (pagination: GridPagination) => void
  onRequestCustomer: (offer: OfferType) => Promise<Customer | null>
  setOfferForView: (offer: OfferType | null) => void
  setIsOfferDrawerOpen: (payload: boolean) => void
  onRefresh: () => void
}

export type OffersGridFilterProps = {
  filter: OfferFilter
  isFetchingData: boolean
  onChange: (filters: OfferFilter) => void
}

export enum OfferAction {
  Approve = 'Approve',
  Counter = 'Counter',
  History = 'History',
  Reject = 'Reject',
  ViewOffer = 'ViewOffer',
  ViewCustomer = 'ViewCustomer',
}

export type ActionAbility =
  | {
      canPerform: true
    }
  | {
      canPerform: false
      reasonMessage: string
    }
