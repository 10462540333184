import * as React from 'react'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'
import { PageAccessControl, useAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Content, ContentHeader, Main } from '@r1/wireframe-primary'
import { Button, Flex, Icon, Link } from '@r1/ui-kit'
import { FacilityDrawer } from '../../components/FacilityDrawer'
import { httpClient } from '../../../../httpClient'
import { useURLSearchParamState } from '../../../../utils/hooks/useURLSearchParamState'
import { apiRoutes, gridId } from './constants'

const initialStateQuery = parseQuery

const aggregationCount = {
  title: 'All facilities',
  suppressAggregation: false,
}

const ActionButton = ({ onAddFacility }: { onAddFacility: () => void }) => {
  const [{ allowFacilityCreate }] = useAccessControl()

  return allowFacilityCreate ? (
    <Button onClick={onAddFacility}>
      <Icon type="plus" color="inverted" />
      Add new
    </Button>
  ) : null
}

export const FacilitiesGrid = () => {
  const { get: getFacilityToEdit, set: setFacilityToEdit } = useURLSearchParamState('facilityId')
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(Boolean(getFacilityToEdit()))

  const openDrawer = React.useCallback(() => setIsDrawerOpen(true), [])
  const closeDrawer = React.useCallback(() => {
    setIsDrawerOpen(false)
    setFacilityToEdit(null)
  }, [])

  const editFacility = React.useCallback(
    (facilityId: string) => () => {
      setFacilityToEdit(facilityId)
      openDrawer()
    },
    [],
  )

  const toolbarActionButtons = React.useMemo(
    () => <ActionButton onAddFacility={openDrawer} />,
    [openDrawer],
  )

  const initialCustomColumnsSettings = React.useMemo(
    () => [
      {
        columnId: 'WarehouseLocationName',
        cell: {
          $type: 'custom',
          renderer: ({ WarehouseLocationId, WarehouseLocationName }: Record<string, string>) => (
            <Link onClick={editFacility(WarehouseLocationId)}>{WarehouseLocationName}</Link>
          ),
        },
      },
    ],
    [editFacility],
  )

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowFacilityView]}>
      <Main fullScreen>
        <ContentHeader />
        <ContentHeader.Title description="List of facilities in a supply chain">
          Facilities
        </ContentHeader.Title>

        <Content>
          <Flex column>
            <GridGenerator
              exportable={false}
              gridId={gridId}
              apiRoutes={apiRoutes}
              initialStateQuery={initialStateQuery}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              httpClient={httpClient}
              aggregationCount={aggregationCount}
              initialCustomColumnsSettings={initialCustomColumnsSettings}
              toolbarActionButtons={toolbarActionButtons}
              onChangeState={setQuery}
            />
          </Flex>
          <FacilityDrawer
            isOpen={isDrawerOpen}
            facilityId={getFacilityToEdit()}
            onClose={closeDrawer}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
