import React, { useState } from 'react'
import { formatISO, parseISO } from 'date-fns'
import { Flex, FormField, TagInput, Select, DatePicker, Button, Input } from '@r1/ui-kit'
import { DatePickerValue } from '@r1/ui-kit/contracts/ts/DatePicker'
import { OfferFilter } from '@r1-webui/gowholesale-offermanagement-v1'
import { OffersGridFilterProps } from '../types'
import { OFFER_STATUS_OPTIONS } from '../const'

type ListFilterType = keyof Pick<OfferFilter, 'offerIds' | 'skus' | 'statuses' | 'upcs'>

type DateFilterType = 'from' | 'to'

const formatFilterBeforeSubmit = (filter: OfferFilter): OfferFilter => {
  return {
    ...filter,
    customerCompany: filter.customerCompany?.trim() || undefined,
  }
}

const formatToDate = (payload: DatePickerValue): DatePickerValue => {
  if (!payload) return payload
  return formatISO(parseISO(payload).setHours(23, 59, 59, 999)).split('+')[0]
}

export const OffersGridFilter = ({
  isFetchingData,
  filter: initialFilter,
  onChange,
}: OffersGridFilterProps) => {
  const [filter, setFilter] = useState<OfferFilter>(initialFilter)

  const onListFilterChange = (type: ListFilterType) => (value: string[]) => {
    setFilter(prevFilter => ({ ...prevFilter, [type]: value }))
  }

  const onCustomerNameChange = (value: string) => {
    setFilter(prevFilter => ({ ...prevFilter, customerCompany: value }))
  }

  const onExpirationDateChange = (type: DateFilterType) => (value: DatePickerValue) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      expirationDate: {
        ...prevFilter.expirationDate,
        [type]: type === 'to' ? formatToDate(value) : value,
      },
    }))
  }

  const onExpirationDateRemove = (type: DateFilterType) => () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      expirationDate: { ...prevFilter.expirationDate, [type]: undefined },
    }))
  }

  const onApproveDateChange = (type: DateFilterType) => (value: DatePickerValue) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      approveDate: {
        ...prevFilter.approveDate,
        [type]: type === 'to' ? formatToDate(value) : value,
      },
    }))
  }

  const onApproveDateRemove = (type: DateFilterType) => () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      approveDate: { ...prevFilter.approveDate, [type]: undefined },
    }))
  }

  const onCreationDateChange = (type: DateFilterType) => (value: DatePickerValue) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      createdDate: {
        ...prevFilter.createdDate,
        [type]: type === 'to' ? formatToDate(value) : value,
      },
    }))
  }

  const onCreationDateRemove = (type: DateFilterType) => () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      createdDate: { ...prevFilter.createdDate, [type]: undefined },
    }))
  }

  const onSearchButtonClick = () => {
    const formattedFilter = formatFilterBeforeSubmit(filter)
    setFilter(formattedFilter)
    onChange(formattedFilter)
  }

  return (
    <Flex column spaceBetween="M">
      <Flex spaceBetween="XL">
        <Flex column basis={1} maxWidth={400} spaceBetween="S">
          <FormField>
            <FormField.Label>Statuses</FormField.Label>
            <Select
              multiple
              tagLimit={1}
              placeholder="Choose Statuses"
              options={OFFER_STATUS_OPTIONS}
              value={filter.statuses ?? []}
              onChange={onListFilterChange('statuses')}
            />
          </FormField>

          <FormField>
            <FormField.Label>Company Name</FormField.Label>
            <Input
              placeholder="Enter value"
              value={filter.customerCompany || ''}
              onChange={onCustomerNameChange}
            />
          </FormField>
        </Flex>

        <Flex column basis={1} maxWidth={400} spaceBetween="S">
          <FormField>
            <FormField.Label>Offer IDs</FormField.Label>
            <TagInput
              $type="simpleTagValue"
              zIndex={2}
              tagLimit={1}
              placeholder="Enter values"
              value={(filter.offerIds ?? []).map(String)}
              accept={/^\d+$/}
              delimiter={['\n', ',']}
              onChange={onListFilterChange('offerIds')}
            />
          </FormField>

          <FormField>
            <FormField.Label>SKUs</FormField.Label>
            <TagInput
              $type="simpleTagValue"
              zIndex={1}
              tagLimit={1}
              placeholder="Enter values"
              value={(filter.skus ?? []).map(String)}
              delimiter={['\n', ',']}
              onChange={onListFilterChange('skus')}
            />
          </FormField>

          <FormField>
            <FormField.Label>UPCs</FormField.Label>
            <TagInput
              $type="simpleTagValue"
              zIndex={1}
              tagLimit={1}
              placeholder="Enter values"
              value={(filter.upcs ?? []).map(String)}
              delimiter={['\n', ',']}
              onChange={onListFilterChange('upcs')}
            />
          </FormField>
        </Flex>

        <Flex column basis={1} maxWidth={400} spaceBetween="S">
          <FormField>
            <FormField.Label>Expiration Date</FormField.Label>
            <Flex spaceBetween="S">
              <DatePicker
                placeholder="From"
                value={filter.expirationDate?.from}
                onChange={onExpirationDateChange('from')}
                onRemove={onExpirationDateRemove('from')}
              />
              <DatePicker
                placeholder="To"
                value={filter.expirationDate?.to}
                onChange={onExpirationDateChange('to')}
                onRemove={onExpirationDateRemove('to')}
              />
            </Flex>
          </FormField>

          <FormField>
            <FormField.Label>Approval Date</FormField.Label>
            <Flex spaceBetween="S">
              <DatePicker
                placeholder="From"
                value={filter.approveDate?.from}
                onChange={onApproveDateChange('from')}
                onRemove={onApproveDateRemove('from')}
              />
              <DatePicker
                placeholder="To"
                value={filter.approveDate?.to}
                onChange={onApproveDateChange('to')}
                onRemove={onApproveDateRemove('to')}
              />
            </Flex>
          </FormField>

          <FormField>
            <FormField.Label>Creation Date</FormField.Label>
            <Flex spaceBetween="S">
              <DatePicker
                placeholder="From"
                value={filter.createdDate?.from}
                onChange={onCreationDateChange('from')}
                onRemove={onCreationDateRemove('from')}
              />
              <DatePicker
                placeholder="To"
                value={filter.createdDate?.to}
                onChange={onCreationDateChange('to')}
                onRemove={onCreationDateRemove('to')}
              />
            </Flex>
          </FormField>
        </Flex>
      </Flex>

      <Flex justify="flex-start">
        <Button loading={isFetchingData} onClick={onSearchButtonClick}>
          Search
        </Button>
      </Flex>
    </Flex>
  )
}
