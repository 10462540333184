import * as React from 'react'
import { Box, Flex, FormField, Input, NumericInput, Select } from '@r1/ui-kit'
import { Facility } from '@r1-webui/facilities-v1'
import { TimeZone } from '@r1-webui/usermanagement-v1'
import { Control, FieldErrors, useController } from 'react-hook-form'
import { Value } from '../../Value'

export const MainSettings = ({
  editable,
  control,
  errors,
  name = '',
  displayName = '',
  accountingName = '',
  numberOfGates = 0,
  numberOfLocalPickupGates = 0,
  timezoneId = '',
  timezones,
}: Partial<Facility> & {
  editable?: boolean
  control: Control
  errors: FieldErrors<Facility>
  timezones: Array<TimeZone> | null
}) => {
  const { field: longName } = useController({
    name: 'accountingName',
    control,
    defaultValue: accountingName,
  })
  const { field: shortName } = useController({
    name: 'name',
    control,
    defaultValue: name,
  })
  const { field: displayNameField } = useController({
    name: 'displayName',
    control,
    defaultValue: displayName,
  })
  const { field: gates } = useController({
    name: 'numberOfGates',
    control,
    defaultValue: numberOfGates,
  })
  const { field: localGates } = useController({
    name: 'numberOfLocalPickupGates',
    control,
    defaultValue: numberOfLocalPickupGates,
  })
  const {
    field: { ref: _ref, ...timezone },
  } = useController({
    name: 'timezoneId',
    control,
    defaultValue: timezoneId,
  })

  const dtiPrefix = 'facility'

  return (
    <Flex column>
      <FormField>
        <FormField.Label>Accounting Location Name</FormField.Label>
        {editable ? (
          <Input {...longName} data-test-id={`${dtiPrefix}__long-name`} />
        ) : (
          <Value>{accountingName}</Value>
        )}
        <FormField.Error>{errors.accountingName?.message}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Short Name</FormField.Label>
        {editable ? (
          <Input {...shortName} data-test-id={`${dtiPrefix}__short-name`} />
        ) : (
          <Value>{name}</Value>
        )}
        <FormField.Error>{errors.name?.message}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Display Name</FormField.Label>
        {editable ? (
          <Input {...displayNameField} data-test-id={`${dtiPrefix}__display-name`} />
        ) : (
          <Value>{displayName}</Value>
        )}
        <FormField.Error>{errors.displayName?.message}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Number of gates</FormField.Label>
        {editable ? (
          <Box maxWidth={188}>
            <NumericInput {...gates} data-test-id={`${dtiPrefix}__gates`} />
          </Box>
        ) : (
          <Value>{numberOfGates}</Value>
        )}
        <FormField.Error>{errors.numberOfGates?.message}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Number of local pickup gates</FormField.Label>
        {editable ? (
          <Box maxWidth={188}>
            <NumericInput {...localGates} data-test-id={`${dtiPrefix}__localGates`} />
          </Box>
        ) : (
          <Value>{numberOfLocalPickupGates}</Value>
        )}
        <FormField.Error>{errors.numberOfLocalPickupGates?.message}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Timezone</FormField.Label>
        {editable ? (
          <Select
            {...timezone}
            multiple={false}
            clearable={false}
            loading={!timezones}
            options={timezones}
            data-test-id={`${dtiPrefix}__timezone`}
          />
        ) : (
          <Value>{timezoneId}</Value>
        )}
        <FormField.Error>{errors.timezoneId?.message}</FormField.Error>
      </FormField>
    </Flex>
  )
}
