/* eslint-disable import/no-unused-modules */
import * as React from 'react'
import { useUserSettings } from '@r1/core-blocks'

import { redirect, redirectToLogin, ROUTES } from '../../navigation'
import { httpClient, addUnauthorizedErrorHandler } from '../../httpClient'
import { Loader } from '../../components/Loader'

import { MasterLayout } from '../MasterLayout/MasterLayout.tsx'

export default ({ children }) => {
  const { authenticateUser, logoutUser, isUserAuthorized } = useUserSettings()

  const handleSystemLogout = React.useCallback(() => {
    logoutUser()
    httpClient.logout()
    redirectToLogin()
  }, [logoutUser])

  React.useEffect(() => {
    const authenticate = async () => {
      const { authenticated } = await authenticateUser()
      if (!authenticated) {
        handleSystemLogout()
      }
    }
    authenticate()
  }, [authenticateUser, handleSystemLogout])

  React.useEffect(() => {
    addUnauthorizedErrorHandler(() => {
      handleSystemLogout()
    })
  }, [handleSystemLogout])

  const handleManualLogout = React.useCallback(() => {
    logoutUser()
    httpClient.logout()
    redirect(ROUTES.LOGIN)
  }, [logoutUser])

  if (!isUserAuthorized) {
    return <Loader />
  }

  return <MasterLayout handleLogout={handleManualLogout}>{children}</MasterLayout>
}
