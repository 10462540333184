import React from 'react'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'

import { GridGenerator } from '@r1/grid-generator'
import { MasterProgramDrawer } from '../../components/MasterProgramDrawer'
import { MasterProgramHistoryDrawer } from '../../components/MasterProgramHistoryDrawer'
import { deleteMasterProgramRule } from '../../services/masterProgramService'
import { useURLSearchParamState } from '../../../../utils/hooks/useURLSearchParamState'
import { MasterProgramGrid } from './MasterProgramGrid'
import { MasterProgramStatisticChart } from './components/MasterProgramStatisticChart'

export const MasterProgramGridScreen: React.FC = () => {
  const { get: getMasterProgramIdFromUrl, set: updateMasterProgramIdInUrl } =
    useURLSearchParamState('ruleId')

  const [masterProgramRuleId, setMasterProgramRuleId] = React.useState<string | null>(null)
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false)
  const [showHistoryDrawer, setShowHistoryDrawer] = React.useState<boolean>(false)

  const gridRef = React.useRef<GridGenerator>(null)

  React.useEffect(() => {
    if (getMasterProgramIdFromUrl()) {
      setMasterProgramRuleId(getMasterProgramIdFromUrl())
      setShowDrawer(true)
    }
  }, [getMasterProgramIdFromUrl, setMasterProgramRuleId, setShowDrawer])

  const closeMasterProgramDrawer = React.useCallback(() => {
    setMasterProgramRuleId(null)
    setShowDrawer(false)
  }, [])

  const handleSubmit = React.useCallback(() => {
    if (gridRef.current) {
      gridRef.current.refreshGrid()
    }
  }, [gridRef])

  const openMasterProgramRule = React.useCallback(
    (ruleId: string) => {
      updateMasterProgramIdInUrl(ruleId)
      setMasterProgramRuleId(ruleId)
      setShowDrawer(true)
      setShowHistoryDrawer(false)
    },
    [updateMasterProgramIdInUrl],
  )

  const openHistoryDrawer = React.useCallback(
    (ruleId: string) => {
      setMasterProgramRuleId(ruleId)
      setShowHistoryDrawer(true)
      setShowDrawer(false)
    },
    [setMasterProgramRuleId],
  )

  const closeHistoryDrawer = React.useCallback(() => {
    setShowHistoryDrawer(false)
    setShowDrawer(true)
  }, [])

  const createMasterProgramRule = React.useCallback(() => {
    setShowDrawer(true)
  }, [])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowMasterProgramView]}>
      <Main fullScreen>
        <ContentHeader>
          <ContentHeader.Title description="List of master programs">
            Master Programs
          </ContentHeader.Title>
        </ContentHeader>
        <Content>
          <MasterProgramStatisticChart />
          <MasterProgramGrid
            gridRef={gridRef}
            onCreateMasterProgram={createMasterProgramRule}
            onEditMasterProgram={openMasterProgramRule}
            onOpenHistoryDrawer={openHistoryDrawer}
            onDeleteMasterProgram={deleteMasterProgramRule}
          />
          {showHistoryDrawer && masterProgramRuleId ? (
            <MasterProgramHistoryDrawer
              ruleId={masterProgramRuleId}
              onOpenRule={openMasterProgramRule}
              onClose={closeHistoryDrawer}
            />
          ) : (
            <MasterProgramDrawer
              masterProgramId={masterProgramRuleId}
              isOpen={showDrawer}
              onOpenHistoryDrawer={openHistoryDrawer}
              onClose={closeMasterProgramDrawer}
              onSubmit={handleSubmit}
            />
          )}
        </Content>
      </Main>
    </PageAccessControl>
  )
}
