import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { handleServerError } from '@r1/core-blocks'
import {
  Box,
  DualList,
  Flex,
  FormField,
  H4,
  HR,
  NotificationSystem,
  Placeholder,
  Select,
} from '@r1/ui-kit'
import type {
  Program,
  ProgramId,
  ProgramPresetId,
  ProgramSearchOption,
} from '@r1-webui/usermanagement-v1'
import { ProgramPreset } from '@r1-webui/usermanagement-v1'
import type { TestableProps } from '@r1/types/typescript'
import type { UserProgramSettings } from '../../types/common'
import { programsApi } from '../../api/programs'
import { DualListContainer, TextReadOnly } from '../common'

type Props = {
  userSettings: UserProgramSettings
  isEditing: boolean
  onChange?: (newSettings: UserProgramSettings) => void
}

type ProgramsAndPresets = {
  programs: Program[]
  programPresets: ProgramPreset[]
  programsAndPresets: { id: string; name: string }[]
  programIdsByPresetId: Map<ProgramPresetId, ProgramId[]>
  programsAndPresetsNamesByIds: Map<string, string>
}

type SelectedProgramAndPresetIds = {
  programIds: ProgramId[]
  programPresetIds: ProgramPresetId[]
}

type ProgramsListProps = {
  allProgramsAndPresets: ProgramsAndPresets
  selectedProgramAndPresetIds: SelectedProgramAndPresetIds
  isEditing: boolean
  onChange: (ids: string[]) => void
}

type SelectLineProps = {
  label: string
  options: { id: string; name: string }[]
  value?: string | null
  isEditing: boolean
  onChange: (id: string) => void
} & TestableProps

const programPresetIdPrefix = 'preset_'
const programIdPrefix = 'program_'

const SelectLine = (props: SelectLineProps) => {
  if (!props.isEditing) {
    const selectedValue = props.options.find(option => option.id === props.value)?.name
    return (
      <Flex column minWidth={1}>
        <HR />
        <Flex justify="space-between" align="flex-start" mt="S">
          <FormField.Label>{props.label}</FormField.Label>
          <TextReadOnly data-test-id={props['data-test-id']}>{selectedValue}</TextReadOnly>
        </Flex>
      </Flex>
    )
  }

  return (
    <FormField>
      <FormField.Label>{props.label}</FormField.Label>
      <Select
        data-test-id={props['data-test-id']}
        options={props.options}
        value={props.value || null}
        clearable={false}
        onChange={props.onChange}
      />
    </FormField>
  )
}

const ProgramsList = (props: ProgramsListProps) => {
  const allSelected = [
    ...props.selectedProgramAndPresetIds.programPresetIds,
    ...props.selectedProgramAndPresetIds.programIds,
  ]

  if (props.isEditing) {
    return (
      <DualListContainer>
        <DualList
          options={props.allProgramsAndPresets.programsAndPresets}
          value={allSelected}
          data-test-id="um-programs-editable-list"
          // @ts-expect-error
          onChange={props.onChange}
        />
      </DualListContainer>
    )
  }

  const names = allSelected.map(id => {
    const elem = props.allProgramsAndPresets.programsAndPresetsNamesByIds.get(id)
    if (!elem) {
      throw new Error(`User's selected program with id "${id}" doesn't exist`)
    }
    return elem
  })

  return (
    <Flex column minWidth={1}>
      <HR />
      <Flex justify="space-between" align="flex-start" mt="S">
        <FormField.Label>Selected Programs</FormField.Label>
        <TextReadOnly>
          <Flex column>
            {names.map(name => (
              <TextReadOnly key={name} data-test-id={`selected-program-${name}`}>
                {name}
              </TextReadOnly>
            ))}
          </Flex>
        </TextReadOnly>
      </Flex>
    </Flex>
  )
}

const { addNotification } = NotificationSystem

export const ProgramsSettings = ({ userSettings, isEditing, onChange }: Props) => {
  const [allProgramsAndPresets, setAllProgramsAndPresets] = useState<ProgramsAndPresets | null>(
    null,
  )
  const [selectedProgramAndPresetIds, setSelectedProgramAndPresetIds] =
    useState<SelectedProgramAndPresetIds>({
      programIds: userSettings.selectedProgramIds.map(id => programIdPrefix + id),
      programPresetIds: userSettings.selectedProgramPresetIds.map(id => programPresetIdPrefix + id),
    })
  const [defaultLoginProgramId, setDefaultLoginProgram] = useState(
    userSettings.defaultLoginProgramId,
  )
  const [defaultSearchOptionId, setDefaultSearchOption] = useState(
    userSettings.defaultSearchOptionId,
  )

  const [isLoadingSearchOptions, setIsLoadingSearchOptions] = useState(true)
  const [searchOptions, setSearchOptions] = useState<ProgramSearchOption[]>([])

  useEffect(() => {
    const loadAllPrograms = async () => {
      const allProgramsResponse = await programsApi.getAllPrograms()
      if (allProgramsResponse.status === 200) {
        const mappedProgramPresets = allProgramsResponse.body.programPresets.map(programPreset => ({
          id: programPresetIdPrefix + programPreset.id,
          name: `(preset) ${programPreset.name}`,
          programIds: programPreset.programIds,
        }))
        const mappedPrograms = allProgramsResponse.body.programs.map(program => ({
          id: programIdPrefix + program.id,
          name: program.name,
        }))
        const mappedProgramsAndPresets = [...mappedProgramPresets, ...mappedPrograms]
        setAllProgramsAndPresets({
          programs: mappedPrograms,
          programPresets: mappedProgramPresets,
          programsAndPresets: mappedProgramsAndPresets,
          programIdsByPresetId: new Map(mappedProgramPresets.map(p => [p.id, p.programIds])),
          programsAndPresetsNamesByIds: new Map(mappedProgramsAndPresets.map(p => [p.id, p.name])),
        })
      } else {
        handleServerError(allProgramsResponse)
        addNotification({ level: 'error', title: "Can't load all programs" })
      }
    }

    loadAllPrograms()
  }, [])

  const selectedProgramIdsIncludingPresetPrograms: ProgramId[] = useMemo(() => {
    if (!allProgramsAndPresets) {
      return []
    }

    const programIdsFromPresets = new Set(
      selectedProgramAndPresetIds.programIds.map(
        id => id.slice(programIdPrefix.length), // Trim prefix
      ),
    )
    selectedProgramAndPresetIds.programPresetIds.forEach(id => {
      const programIds = allProgramsAndPresets.programIdsByPresetId.get(id)

      if (!programIds) {
        throw new Error(`Program preset with id "${id}" doesn't exist`)
      }

      programIds.forEach(programId => programIdsFromPresets.add(programId))
    })
    return [...programIdsFromPresets]
  }, [allProgramsAndPresets, selectedProgramAndPresetIds])

  useEffect(() => {
    const loadNewOptions = async (selectedPrograms: ProgramId[]) => {
      setIsLoadingSearchOptions(true)
      const searchOptionsResponse = await programsApi.buildSearchOptions(selectedPrograms)
      if (searchOptionsResponse.status === 200) {
        setSearchOptions(searchOptionsResponse.body)
        setIsLoadingSearchOptions(false)
      } else {
        handleServerError(searchOptionsResponse)
        addNotification({ level: 'error', title: "Can't load search options" })
      }
    }

    if (selectedProgramIdsIncludingPresetPrograms.length) {
      loadNewOptions(selectedProgramIdsIncludingPresetPrograms)
    }
  }, [selectedProgramIdsIncludingPresetPrograms])

  // remove default search program if search options don't contain it
  useEffect(() => {
    if (!allProgramsAndPresets || isLoadingSearchOptions || !defaultSearchOptionId) {
      return
    }
    if (!searchOptions.some(option => option.id === defaultSearchOptionId)) {
      setDefaultSearchOption(undefined)
    }
  }, [allProgramsAndPresets, isLoadingSearchOptions, searchOptions, defaultSearchOptionId])

  // remove default login program if selected programs don't contain it
  useEffect(() => {
    if (!allProgramsAndPresets || !defaultLoginProgramId) {
      return
    }
    if (!selectedProgramIdsIncludingPresetPrograms.includes(defaultLoginProgramId)) {
      setDefaultLoginProgram(undefined)
    }
  }, [allProgramsAndPresets, selectedProgramIdsIncludingPresetPrograms, defaultLoginProgramId])

  useEffect(() => {
    if (onChange) {
      onChange({
        selectedProgramIds: selectedProgramAndPresetIds.programIds.map(
          id => id.slice(programIdPrefix.length), // Trim prefix
        ),
        selectedProgramPresetIds: selectedProgramAndPresetIds.programPresetIds.map(
          id => id.slice(programPresetIdPrefix.length), // Trim prefix
        ),
        defaultLoginProgramId,
        defaultSearchOptionId,
      })
    }
  }, [selectedProgramAndPresetIds, defaultLoginProgramId, defaultSearchOptionId])

  const defaultLoginPrograms = useMemo(() => {
    if (!allProgramsAndPresets) {
      return []
    }
    return selectedProgramIdsIncludingPresetPrograms.map(id => {
      const name = allProgramsAndPresets.programsAndPresetsNamesByIds.get(programIdPrefix + id)
      if (!name) {
        throw new Error(`User's selected program with id "${id}" doesn't exist`)
      }
      return {
        id,
        name,
      }
    })
  }, [allProgramsAndPresets, selectedProgramIdsIncludingPresetPrograms])

  const handleProgramsSelectionChanged = useCallback((ids: string[]) => {
    setSelectedProgramAndPresetIds({
      programIds: ids.filter(id => id.startsWith(programIdPrefix)),
      programPresetIds: ids.filter(id => id.startsWith(programPresetIdPrefix)),
    })
  }, [])

  return !allProgramsAndPresets ? (
    <Placeholder type="form" height={5} />
  ) : (
    <Flex column spaceBetween="XL">
      <H4>Programs Settings</H4>
      <Flex column minWidth={650} maxWidth={650} spaceBetween="M">
        <Box>
          <SelectLine
            label="Default Login Program"
            data-test-id="default-login-program"
            isEditing={isEditing}
            options={defaultLoginPrograms}
            value={defaultLoginProgramId}
            onChange={setDefaultLoginProgram}
          />
        </Box>
        <Box>
          <SelectLine
            label="Default Search Program"
            data-test-id="default-search-program"
            isEditing={isEditing}
            options={searchOptions}
            value={defaultSearchOptionId}
            onChange={setDefaultSearchOption}
          />
        </Box>
        <Box>
          <ProgramsList
            isEditing={isEditing}
            allProgramsAndPresets={allProgramsAndPresets}
            selectedProgramAndPresetIds={selectedProgramAndPresetIds}
            onChange={handleProgramsSelectionChanged}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
