/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { cookie, createClient } from '@r1/http-client'
import { AxiosResponse } from 'axios'
import { authMiddleware, setLegacyAuthInitializer } from './authMiddleware'
import { errorMiddleware } from './errorMiddleware'
import { mockR1UserManagement } from './interceptors/mockR1UserManagement'

/**
 * @var httpClient is a http client for the new/thor packages: wireframe-primary.
 * This approach are implemented because there are errors with parsing error responses in the new modules.
 * It happens because new modules are used web-ui api under the hood  and web-ui map error response like this:
 * const mapToError = (err) => {
    const {
      status,
      data
    } = err.response
    return {
      status: status,
      body: data
    }
   }
 */

/**
 * when we login with iosLogin by login label we dont get refreshToken.
 * instead, we set a stub and the code of application can know that it was logged in
 * by login label
 */
const REFRESH_TOKEN_MOCK_FOR_IOS = 'IOS_LOGIN_REFRESH_TOKEN_MOCK'

const httpClient = createClient()(authMiddleware, errorMiddleware)

setLegacyAuthInitializer(() => {
  httpClient.post('/ui/api/identity/init').then(() => {})
})

const headers = {
  'Content-Type': 'application/json',
}

httpClient.defaults.headers.post = headers

httpClient.defaults.headers.put = headers

httpClient.interceptors.request.use(req => {
  /**
   * we should check whether the user is logging via ios login since
   * when logging in with login label user will NOT be granted with refreshToken & accessToken
   * and without refreshToken it is impossible to fetch secondary catalogs menu items (azure catalog service)
   * so we abort this request for ios users
   *
   * https://jira.trgdev.com/browse/R1S-7516
    https://jira.trgdev.com/browse/R1S-7550
   */
  if (
    cookie.getItem('refreshToken') === REFRESH_TOKEN_MOCK_FOR_IOS &&
    req.url === '/api/catalog-view/catalogs/secondary'
  ) {
    // eslint-disable-next-line no-throw-literal
    throw { shouldAbort: true, data: [] }
  }
  return req
})

httpClient.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.shouldAbort) {
      return Promise.resolve(error.data)
    }

    /**
     * In cases when some requests have been canceled (ex. an user close a page) errors of these requests won't have the response field
     * It causes errors during mapping errors objects in @r1-webui packages which are expected error.response field.
     */

    if (!error.response) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        response: {
          status: 0,
          data: {
            message: error.message,
          },
        },
      })
    }

    return Promise.reject(error)
  },
)

httpClient.interceptors.response.use(
  mockR1UserManagement.response as unknown as (a: AxiosResponse) => {
    /**/
  },
  mockR1UserManagement.error,
)

export { httpClient }
