import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Modal, Text, Flex, Box, Loader, Link } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import { PickupSingle } from '@r1-webui/facilitypickupscheduler-v1'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { deletePickup, getOrderPickup } from '../../../../services/schedulePickupService'
import { utcToLocal } from '../../../../../../utils/dates/utcToLocal'
import { h12Format } from '../../../../../../utils/dates/formats'
import { DetailsPickupModalProps } from './DetailsPickupModalProps'

type CardFieldProps = {
  label: string
  children: JSX.Element
}

const CardField = ({ label, children }: CardFieldProps) => {
  return (
    <Flex align="center">
      <Box basis={0.3}>
        <Text weight="medium" type="paragraph">
          {label}:
        </Text>
      </Box>
      <Box grow>{children}</Box>
    </Flex>
  )
}

type OrderLinkProps = {
  orderId: string
  companyOrderId: string
}

const OrderLink = (props: OrderLinkProps) => {
  return (
    <Link href={`/Modules/B2B/OrderDetails.aspx?id=${props.orderId}`} target="_blank">
      {props.companyOrderId}
    </Link>
  )
}

const PickupPeriod = ({ date, start, end }: Record<string, string>) => {
  const pickupDate = format(utcToLocal(date), 'MM/dd/yyyy')
  const pickupStart = format(utcToLocal(start), h12Format)
  const pickupEnd = format(utcToLocal(end), h12Format)
  const period = `${pickupDate} ${pickupStart} - ${pickupEnd}`

  return <Text>{period}</Text>
}

export const DetailsPickupSingleModal = ({
  warehouseLocationId,
  pickupId,
  pickupType,
  onClose,
  onDelete,
}: DetailsPickupModalProps) => {
  const [{ allowPickupScheduleEdit }] = useAccessControl()
  const [loading, setLoading] = useState<boolean>(false)
  const [pickup, setPickup] = useState<PickupSingle | null>(null)

  useEffect(() => {
    const fetchPickupDescription = async () => {
      const pickupDetails = (await getOrderPickup(
        warehouseLocationId,
        pickupId,
        pickupType,
      )) as PickupSingle
      if (pickupDetails) {
        setPickup(pickupDetails)
      } else {
        onClose()
      }
    }

    fetchPickupDescription()
  }, [warehouseLocationId, pickupId, pickupType, onClose])

  const modalActionButtons: ControlledActionButtons = [
    {
      title: 'Close',
      onClick: onClose,
      align: 'left',
      transparent: true,
    },
    {
      title: 'Delete',
      disabled: !allowPickupScheduleEdit || loading,
      onClick: async () => {
        setLoading(true)
        const success = await deletePickup(warehouseLocationId, pickupId, pickupType)
        setLoading(false)
        if (success) {
          onClose()
          onDelete(pickupId)
        }
      },
      align: 'right',
      color: 'danger',
      loading,
    },
  ]

  const order = pickup?.orders[0]
  const shipmentsExists =
    (pickup?.orders?.reduce((count, o) => count + (o.shipmentIds?.length || 0), 0) || 0) > 0

  return (
    <Modal
      isControlled
      show
      size="M"
      title="Pickup Details"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onClose}
    >
      {!order ? (
        <Flex justify="center" align="center">
          <Loader />
        </Flex>
      ) : (
        <Flex column spaceBetween="L">
          <CardField label="Pickup Date & Time">
            <PickupPeriod date={pickup.pickupDate} {...pickup.period} />
          </CardField>
          <CardField label="Orders">
            <Flex spaceBetween="M" maxWidth={0.5}>
              {pickup.orders?.map(pickupOrder => (
                <Box key={pickupOrder.id}>
                  <OrderLink orderId={pickupOrder.id} companyOrderId={pickupOrder.companyOrderId} />
                </Box>
              ))}
            </Flex>
          </CardField>
          <CardField label="Order Type">
            <Text>{pickup.orderType}</Text>
          </CardField>
          <CardField label="Customer Name">
            <Text>{pickup.customerName}</Text>
          </CardField>
          <CardField label="Marketplace PO#">
            <Text>{order.marketplacePoNumber}</Text>
          </CardField>
          {shipmentsExists && (
            <CardField label="Shipment">
              <Flex flexWrap justify="flex-start" maxWidth={0.9}>
                {pickup.orders?.map(({ companyOrderId, shipmentIds }) =>
                  shipmentIds?.map(shipmentId => (
                    <Box key={shipmentId} pl="S" pr="S">
                      <Link
                        href={`/Modules/Shipping/ShipmentDetails.aspx?shid=${shipmentId}`}
                        target="_blank"
                      >
                        {companyOrderId}
                      </Link>
                    </Box>
                  )),
                )}
              </Flex>
            </CardField>
          )}
          <CardField label="Package Type">
            <Text>
              {(pickup.palletCount > 0 ? `Pallets count: ${pickup.palletCount}` : '') +
                (pickup.palletCount > 0 && pickup.productCount > 0 ? ', ' : '') +
                (pickup.productCount > 0 ? `Products count: ${pickup.productCount}` : '')}
            </Text>
          </CardField>
          <CardField label="Carrier">
            <Text>{pickup.carrier}</Text>
          </CardField>
          <CardField label="Created By">
            <Text>{pickup.createdByName || '-'}</Text>
          </CardField>
          <CardField label="Created On">
            <Text>
              {pickup.createdOn ? format(utcToLocal(pickup.createdOn), 'MM/dd/yyyy, hh:mm a') : '-'}
            </Text>
          </CardField>
          <CardField label="Updated By">
            <Text>{pickup.updatedByName || '-'}</Text>
          </CardField>
          <CardField label="Updated On">
            <Text>
              {pickup.updatedOn ? format(utcToLocal(pickup.updatedOn), 'MM/dd/yyyy, hh:mm a') : '-'}
            </Text>
          </CardField>
          {pickup.shippingBundleId && (
            <Box alignSelf="flex-end">
              <Link href={`/ui/api/pickups/bols/${pickup.shippingBundleId}`}>Download BOL</Link>
            </Box>
          )}
        </Flex>
      )}
    </Modal>
  )
}
