/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Flex } from '@r1/ui-kit'
import {
  Divider,
  Logo,
  PrintLoginLabelModal,
  TutorialsMenu,
  WarehousesApiService,
  WireframeProvider,
} from '@r1/wireframe-primary'

import {
  CookieBanner,
  CookieDrawer,
  CookieGroup,
  getDisabledCookiesFromLocalStorage,
  getIsCookieBannerShown,
  setDisabledCookiesToLocalStorage,
} from '@r1/cookie-consent-tool'

// TypeScript don't like when I use regular JS code with flow so I've commented them all
import { HttpClient } from '@r1/wireframe-primary/typings/WireframeProvider/WireframeProvider'
// @ts-ignore
import { getUserAccount } from '../../modules/userAccount'
import {
  BackgroundTasksContainer,
  HelpMenu,
  MemberLogo,
  SettingsMenu,
  // @ts-ignore
} from '../../components/NavigationHeader'
import { httpClient } from '../../httpClient'
// @ts-expect-error
import { browserHistory } from '../browserHistory'
// @ts-expect-error
import { useToggle } from '../../utils'
import { useAccessControl } from '../../containers/user-management/hooks'
import { isInIframe } from '../../utils/isInIframe'
import { COOKIE_LIST } from './cookieList'
import { useRMP } from './useRMP'
import { INavigationItemWithRequiredPermissions, useNavigationApi } from './hooks/useNavigationAPI'
import { checkRMPMenuAccess, isRMP } from './utils'
import { ReturnProHeaderLogo } from './components/ReturnProHeaserLogo'

type MasterLayoutProps = React.PropsWithChildren<{ handleLogout: () => void }>

const PAGE_PREFIX_URL = '/r1'

const renderHeaderLeftSide = () => {
  if (isRMP) {
    return (
      <>
        <Divider ml="XS" mr="M" />
        <ReturnProHeaderLogo />
      </>
    )
  }

  return (
    <Flex align="center">
      <Logo pagePrefixUrl={PAGE_PREFIX_URL} />
      <Divider ml="XS" mr="M" />
      <MemberLogo iconName="goTrgLight" />
    </Flex>
  )
}

const getRenderHeaderRightSide =
  ({ onCookieBannerOpen }: { onCookieBannerOpen: () => void }) =>
  () =>
    (
      <Flex align="center" spaceBetween="XS">
        {!isRMP && (
          <>
            <TutorialsMenu />
            <SettingsMenu />
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <BackgroundTasksContainer httpClient={httpClient} />
          </>
        )}
        <HelpMenu
          isRMP={isRMP}
          pagePrefixUrl={PAGE_PREFIX_URL}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          httpClient={httpClient}
          onCookieBannerOpen={onCookieBannerOpen}
        />
      </Flex>
    )

export const MasterLayout = (props: MasterLayoutProps) => {
  const { children, handleLogout } = props
  const allowedPermissions = useAccessControl()
  useRMP()
  const [userAccount, setUserAccount] = useState()
  const [isCookieDrawerShown, setCookieDrawerShown] = useState(false)
  const [isCookieBannerShown, setCookieBannerShown] = useState(false)
  const [disabledCookies, setDisabledCookies] = useState<CookieGroup[]>([])

  const isPageOpenedInIframe = useMemo(() => isInIframe(), [])

  useEffect(() => {
    setCookieBannerShown(getIsCookieBannerShown())
  }, [])

  useEffect(() => {
    const cookies = getDisabledCookiesFromLocalStorage()
    if (cookies) {
      setDisabledCookies(cookies)
    }
  }, [])

  const onSaveCookieChoice = (v: CookieGroup[]) => {
    if (v.includes('performance')) {
      if (window.gtag) {
        window.gtag('consent', 'update', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied',
        })
      }
    } else if (window.gtag) {
      window.gtag('consent', 'update', {
        analytics_storage: 'granted',
      })
    }
    setDisabledCookiesToLocalStorage(v)
    setDisabledCookies(v)
    setCookieBannerShown(false)
    setCookieDrawerShown(false)
  }

  const onDisableAllCookies = useCallback(() => {
    setDisabledCookiesToLocalStorage(['performance'])
    setCookieBannerShown(false)
  }, [])

  const onAcceptAllCookies = useCallback(() => {
    setDisabledCookiesToLocalStorage([])
    setCookieBannerShown(false)
  }, [])

  const { state: printLoginLabelModal, toggle: togglePrintLoginLabelModal } = useToggle(false)

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUserAccount()
      setUserAccount(user)
    }

    fetchUser()
  }, [])

  const warehousesApi = React.useMemo(() => new WarehousesApiService(httpClient), [])
  const navigationApi = useNavigationApi()
  const accountMenuItems = React.useMemo(
    () => [
      { id: 'printLoginLabel', title: 'Print Login Label', action: togglePrintLoginLabelModal },
    ],
    [togglePrintLoginLabelModal],
  )

  const getMenuItems = async (programId: number | null) => {
    const result: INavigationItemWithRequiredPermissions[] = await navigationApi.getMenuItems(
      programId,
    )
    return result.filter(item => {
      // if menuItem permissions isn't set or empty - menu item will be rendered
      if (!item.permissions || !item.permissions.length) {
        return true
      }
      return item.permissions.some(menuPermission =>
        allowedPermissions.some(userPermission =>
          isRMP
            ? checkRMPMenuAccess(userPermission, menuPermission)
            : userPermission.toLowerCase() === menuPermission.toLowerCase(),
        ),
      )
    })
  }

  const renderHeaderRightSide = React.useMemo(
    () => getRenderHeaderRightSide({ onCookieBannerOpen: () => setCookieDrawerShown(true) }),
    [],
  )

  const withSearch = useMemo(() => (isRMP ? false : 'All'), [isRMP])

  return (
    <WireframeProvider
      key={allowedPermissions.length}
      allowExport
      hideFacilitySelect={!!isRMP}
      withSearch={withSearch}
      pagePrefixUrl={PAGE_PREFIX_URL}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      userAccount={userAccount}
      httpClient={httpClient as HttpClient}
      warehousesApi={warehousesApi}
      navigationApi={{ ...navigationApi, getMenuItems }}
      browserHistory={browserHistory}
      renderHeaderLeftSide={renderHeaderLeftSide}
      renderHeaderRightSide={renderHeaderRightSide}
      accountMenuItems={accountMenuItems}
      onLogout={handleLogout}
    >
      {children}
      <CookieDrawer
        show={isCookieDrawerShown}
        cookies={COOKIE_LIST}
        privacyPolicyLink="https://www.gotrg.com/legal/privacy"
        cookiePolicyLink="https://www.gotrg.com/legal/cookie-policy"
        termsOfServiceLink="https://www.gotrg.com/legal/terms"
        disabledCookies={disabledCookies}
        onSave={onSaveCookieChoice}
        onClose={() => setCookieDrawerShown(false)}
      />
      {isCookieBannerShown && !isPageOpenedInIframe && (
        <CookieBanner
          onAcceptAll={onAcceptAllCookies}
          onDeclineAll={onDisableAllCookies}
          onCustomize={() => setCookieDrawerShown(true)}
        />
      )}
      <PrintLoginLabelModal isOpen={printLoginLabelModal} onClose={togglePrintLoginLabelModal} />
    </WireframeProvider>
  )
}
