import React from 'react'
import parsePhoneNumber from 'libphonenumber-js'
import { Text } from '@r1/ui-kit'

const formatPhoneNumber = (phoneNumber?: string): string => {
  if (!phoneNumber) return '-'
  const phone = parsePhoneNumber(phoneNumber)
  return phone ? phone.formatInternational() : phoneNumber
}

export const CustomerPhoneNumber = React.memo<{ phoneNumber?: string }>(({ phoneNumber }) => {
  return <Text>{formatPhoneNumber(phoneNumber)}</Text>
})
