import { useEffect } from 'react'

export const useRMP = () => {
  useEffect(() => {
    if (window.location.search.includes('?fromRMP')) {
      localStorage.setItem('isRMP', 'true')
      window.location.href = window.location.href.replace('?fromRMP', '')
    }
  }, [])
}
