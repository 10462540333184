import React, { useState, useCallback } from 'react'
import { Modal, Flex, FormField, DatePicker, Button, Icon, Input, Select } from '@r1/ui-kit'

import { useAccessControl } from '@r1/core-blocks'

import { add, format, getDay, isSameDay } from 'date-fns'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { Order } from '@r1-webui/facilitypickupscheduler-v1'
import { getBundleCandidates, createPickupBundle } from '../../../../services/schedulePickupService'
import { Period } from '../../types'
import { useExceptionDates } from '../../hooks/useExceptionDates'
import { mergeDateWithTime } from '../../../../../../utils/dates/mergeDateWithTime'
import { ISOFormat } from '../../../../../../utils/dates/formats'
import { generateTimeIntervals } from './CreateSinglePickupModal'

export type CreateOrderBundleEvent = {
  rootOrderId: number
  orderIdsToBundle: number[]
  start: Date | string
  end: Date | string
}

export type CreateOrderBundleModalProps = {
  warehouseLocationId: string
  warehouseWorkingHours: Record<number, Period>
  warehouseExceptionDates: string[]
  refreshCalendarEvents: () => Promise<void>
}

export const CreateOrderBundleModal = (props: CreateOrderBundleModalProps) => {
  const [orderId, setOrderId] = useState<string | null>(null)
  const [canFetchBundleCandidateOrders, setCanFetchBundleCandidateOrders] = React.useState(false)
  const [selectedOrdersToBundle, setSelectedOrdersToBundle] = useState<string[]>([])
  const [ordersToBundleSource, setOrdersToBundleSource] = useState<Order[]>([])
  const [isOrdersBundleFetching, setOrdersBundleFetching] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [pickupDate, setPickupDate] = useState<string | null>(null)
  const [pickupTime, setPickupTime] = useState<string | null>(null)
  const [show, setShow] = useState<boolean>(false)

  const [{ allowPickupScheduleEdit }] = useAccessControl()

  const exceptionDates = useExceptionDates(props.warehouseExceptionDates)

  React.useEffect(() => {
    async function getBundleCandidateOrders() {
      if (orderId) {
        setOrdersBundleFetching(true)
        const result = await getBundleCandidates(props.warehouseLocationId, orderId)
        setOrdersBundleFetching(false)
        if (result) {
          setOrdersToBundleSource(result)
        }
      }
    }

    if (canFetchBundleCandidateOrders) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getBundleCandidateOrders()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canFetchBundleCandidateOrders, props.warehouseLocationId])

  const onModalClose = useCallback(() => {
    setShow(false)
    setSelectedOrdersToBundle([])
    setOrderId(null)
    setPickupDate(null)
    setPickupTime(null)
  }, [])

  const onSave = async () => {
    if (orderId && pickupDate && props.warehouseLocationId) {
      const start = mergeDateWithTime(pickupDate, pickupTime)
      const end = add(mergeDateWithTime(pickupDate, pickupTime), { hours: 1 })
      const timeStart = format(start, ISOFormat)
      const timeEnd = format(end, ISOFormat)

      const createPickupParams = {
        orderIds: selectedOrdersToBundle.concat([orderId]),
        pickupDate,
        timeStart,
        timeEnd,
      }

      setIsSaving(true)
      await createPickupBundle(props.warehouseLocationId, createPickupParams)
      setIsSaving(false)
      await props.refreshCalendarEvents()
    }
    onModalClose()
  }

  const isFormFilled = orderId && pickupDate && pickupTime

  const modalActionButtons: ControlledActionButtons = [
    {
      title: 'Close',
      onClick: onModalClose,
      align: 'left',
      transparent: true,
    },
    {
      title: 'Save',
      onClick: onSave,
      align: 'right',
      disabled: !isFormFilled || isSaving,
      loading: isSaving,
    },
  ]

  const intervalOptions = React.useMemo(() => {
    if (pickupDate) {
      const date = new Date(pickupDate)

      if (exceptionDates.some(exceptionDate => isSameDay(exceptionDate, date))) return []

      const day = getDay(date)
      const period = props.warehouseWorkingHours[day]
      const intervalTimes = (period && generateTimeIntervals(period)) || []
      return intervalTimes.map(interval => ({ id: interval, name: interval }))
    }
    return []
  }, [pickupDate, exceptionDates, props.warehouseWorkingHours])

  const orderCandidateOptions = ordersToBundleSource.map(({ companyOrderId }) => ({
    id: companyOrderId,
    name: companyOrderId,
  }))

  const onSelectChange = (values: string[]) => {
    setSelectedOrdersToBundle(values)
  }

  const onOrderIdFocus = React.useCallback(() => setCanFetchBundleCandidateOrders(false), [])
  const onOrderIdBlur = React.useCallback(() => setCanFetchBundleCandidateOrders(true), [])

  return (
    <React.Fragment>
      <Button
        disabled={!allowPickupScheduleEdit || !props.warehouseLocationId}
        data-test-id="shipping__schedule-pickup__create-order-bundle__button"
        onClick={() => setShow(true)}
      >
        <Icon type="plus" />
        Bundle Pickup
      </Button>
      <Modal
        isControlled
        show={show}
        size="M"
        title="Create bundle pickup"
        actionButtons={modalActionButtons}
        onEscapeKeyDown={onModalClose}
      >
        <Flex column>
          <FormField>
            <FormField.Label>Order ID</FormField.Label>
            <Input
              value={orderId || ''}
              data-test-id="shipping__schedule-pickup__create-order-bundle__order-id-input"
              onChange={setOrderId}
              onFocus={onOrderIdFocus}
              onBlur={onOrderIdBlur}
            />
          </FormField>
          <FormField>
            <FormField.Label>Orders to bundle</FormField.Label>
            <Select
              multiple
              disabled={!orderId}
              loading={isOrdersBundleFetching}
              value={selectedOrdersToBundle}
              options={orderCandidateOptions}
              data-test-id="shipping__schedule-pickup__create-order-bundle__orders-to-bundle-select"
              onChange={onSelectChange}
            />
          </FormField>
          <FormField>
            <FormField.Label>Pickup Date</FormField.Label>
            <DatePicker
              value={pickupDate}
              data-test-id="shipping__schedule-pickup__create-order-bundle__pickup-date-date-picker"
              onChange={setPickupDate}
            />
          </FormField>
          <FormField>
            <FormField.Label>Pickup Period</FormField.Label>
            <Select
              value={pickupTime}
              options={intervalOptions}
              data-test-id="shipping__schedule-pickup__create-order-bundle__pickup-period-select"
              onChange={setPickupTime}
            />
          </FormField>
        </Flex>
      </Modal>
    </React.Fragment>
  )
}
