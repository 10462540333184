import * as z from 'zod'

const isValid = (value: unknown) => Boolean(value)

const addressSchema = z
  .object({
    countryCode: z.string().nullable().refine(isValid, 'Please choose Country'),
    addressLine1: z.string().nullable().refine(isValid, 'Please enter Address'),
    addressLine2: z.string().nullable().optional(),
    cityName: z.string().nullable().refine(isValid, 'Please enter City'),
    postalCode: z.string().nullable().refine(isValid, 'Please enter postal code'),
  })
  .partial()

const scheduleDaySchema = z.object({
  day: z.string().refine(isValid, 'Please select Day'),
  periods: z.array(
    z.object({
      from: z.string(),
      to: z.string(),
    }),
  ),
})

const periodSchema = z.object({
  from: z.string(),
  to: z.string(),
  startDay: z.string().refine(isValid, 'Please select Start Day'),
  finishDay: z.string().refine(isValid, 'Please select Finish Day'),
})

const shiftSchema = z.object({
  name: z.string().refine(isValid, 'Please fill Shift Name'),
  periods: z.array(periodSchema),
})

const cutOffTimeSchema = z.object({
  carrierId: z.string().refine(isValid, 'Please select Carrier'),
  periods: z.array(periodSchema),
})

export const schema = z.object({
  accountingName: z.string().nullable().refine(isValid, 'Please fill Accounting Location'),
  name: z.string().nullish().refine(isValid, 'Please fill Short Name'),
  displayName: z.string().nullish().refine(isValid, 'Please fill Display Name'),
  numberOfGates: z
    .string()
    .or(z.number())
    .refine((value: unknown) => Number(value) > -1, 'Please enter number of Gates'),
  numberOfLocalPickupGates: z
    .string()
    .or(z.number())
    .refine((value: unknown) => Number(value) > -1, 'Please enter number of local pickup Gates'),
  timezoneId: z.string().nullable().optional(), // .refine(isValid, 'Please select Timezone'),
  addresses: z.object({
    Physical: addressSchema,
    Shipping: addressSchema,
  }),
  schedule: z.array(scheduleDaySchema),
  nonWorkingDays: z.array(z.string()),
  shifts: z.array(shiftSchema),
  shippingCutOffTimes: z.array(cutOffTimeSchema),
})
