import {
  createOfferManagementApi,
  OfferFilter,
  OfferManagement,
  OfferPreview,
} from '@r1-webui/gowholesale-offermanagement-v1'
import { createCustomersApi } from '@r1-webui/customerprovider-v1'
import { handleServerError } from '@r1/core-blocks'
import { format } from 'date-fns'
import { httpClient } from '../../httpClient/httpClient'
import { downloadBlob } from '../../utils/download'
import { GridPagination } from './types'

const OFFER_MANAGEMENT_URL_PREFIX = '/uisvc/gowholesale-offer-management'

const offerManagementApiInner = createOfferManagementApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: OFFER_MANAGEMENT_URL_PREFIX,
})

const expandOfferFilter = (filter: OfferFilter): OfferFilter => {
  const filterStatuses = [...(filter?.statuses ?? [])]

  if (filterStatuses.includes('Expired')) {
    filterStatuses.push('AcceptExpired', 'Outdated')
  }
  if (filterStatuses.includes('Cancelled')) {
    filterStatuses.push('AcceptFailed', 'CancelAccepted', 'RejectAccepted')
  }

  return { ...filter, statuses: filterStatuses }
}

export const offerManagementApi = {
  ...offerManagementApiInner,

  exportAndDownloadOffers: async ({ filter }: { filter: OfferFilter }) => {
    const expandedFilter = expandOfferFilter(filter)

    const fileBlob = await httpClient.post<Blob>(
      `${OFFER_MANAGEMENT_URL_PREFIX}/gowholesale/offer-management/offers/export`,
      { filter: expandedFilter },
      { responseType: 'blob' },
    )

    downloadBlob({
      name: `${format(Date.now(), 'MM-dd-yyyy HH-mm')} Offer Management.xlsx`,
      blob: fileBlob,
    })
  },

  getSimilarHigherOffers: async ({
    offerId,
    price,
    sku,
  }: {
    offerId: string
    price: string
    sku: string
  }): Promise<string[]> => {
    const response = await offerManagementApiInner.getSimilarHigherOffers(
      {
        offerId,
      },
      {
        price,
        sku,
      },
    )
    if (response.status === 200) return response.body
    handleServerError(response)
    return []
  },

  getSimilarHigherHistoryOffers: async ({
    offerId,
    price,
    sku,
    recentTimespanInDays,
  }: {
    offerId: string
    price: string
    sku: string
    recentTimespanInDays: number
  }): Promise<string[]> => {
    const response = await offerManagementApiInner.getHistorySimilarHigherOffers(
      {
        offerId,
      },
      {
        sku,
        price,
        recentTimespanInDays,
      },
    )
    if (response.status === 200) return response.body
    handleServerError(response)
    return []
  },

  getSimilarOffers: async (offerId: string): Promise<OfferPreview[]> => {
    const response = await offerManagementApiInner.getSimilarOffers({ offerId })
    if (response.status === 200) return response.body
    handleServerError(response)
    return []
  },

  getOffers: async ({
    filter,
    pagination,
  }: {
    filter: OfferFilter
    pagination: GridPagination
  }): Promise<OfferManagement.SearchOffersResponse> => {
    const expandedFilter = expandOfferFilter(filter)

    return offerManagementApi.searchOffers({
      filter: expandedFilter,
      page: pagination.page - 1,
      pageSize: pagination.pageSize,
    })
  },
}

export const customerProviderApi = createCustomersApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `/uisvc/customer-provider`,
})
